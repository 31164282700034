<div [className]="className" class="field-block">
  <ng-content></ng-content>

  <div class="error-message text-error validation-error-block" *ngIf="getErrors">
    <div *ngFor="let err of getErrors">
      <div [ngSwitch]="err">
        <div *ngSwitchCase="'required'">
          The field is required
        </div>
        <div *ngSwitchCase="'email'">
          The email is incorrect
        </div>
        <div *ngSwitchCase="'incorrectCode'">
          The code is incorrect
        </div>
        <div *ngSwitchDefault>
          {{ err }}
        </div>
      </div>
    </div>
  </div>
</div>



