import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class NotificationService {

    constructor(private _localNotifications: LocalNotifications) { }

    public init(): void{
        this._localNotifications.schedule({
            id: 1,
            text: 'Your girlfriends are waiting for you!',
            trigger: {at: new Date(new Date().getTime() + 86400000)}
        });
    }

}
