import {
    Injectable,
} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {
    LocalStorageService,
} from './localStorage.service';

@Injectable()
export class JWTTokenService {
    jwtToken?: string;
    private _eroGamesToken: string;
    decodedToken?: {
        [key: string]: string;
    };

    get erogamesToken(): string {
      let token = this._eroGamesToken;
      if(!token){
        token = this._localStorageService.get('erogames_token');
      }
      return token || null;
    }

    set erogamesToken(token: string | null) {
      if(token === null) {
        this._localStorageService.remove('erogames_token');
        this._eroGamesToken = null;
      }
      if(token) {
        this._localStorageService.set('erogames_token', token);
          this._eroGamesToken = token;
      }
    }

    constructor(
      private _localStorageService: LocalStorageService,
      ) {
        this._init();
    }

    private _init() {
        const token = this._localStorageService.get('nudes_token');
        if (token && token !== '') {
            this.jwtToken = token;
        }
    }

    setToken(token: string) {
        if (token) {
            this.jwtToken = token;
            this._localStorageService.set('nudes_token', token);
        }
    }

    removeToken(): void{
        this._localStorageService.remove('nudes_token');
        this.jwtToken = '';
    }

    getToken(): string | null {
        if (this.jwtToken && this.jwtToken !== '') {
            return this.jwtToken;
        }
        return null;
    }

    decodeToken() {
        if (this.jwtToken) {
            this.decodedToken = jwt_decode(this.jwtToken);
        }
    }

    getDecodeToken() {
        return jwt_decode(this.jwtToken || '');
    }

    getUserEmail() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] : null;
    }

    getRoles() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : null;
    }

    getExpiryTime() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.exp : null;
    }

    isTokenExpired(): boolean {
        const expiryTime: number = +(this.getExpiryTime() || '');
        if (expiryTime) {
            return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
        }
        return false;
    }
}
