<div class="game-bg" *ngIf="showBg">
  <div class="game-bg-left-side"></div>
  <div class="game-bg-right-side"></div>
</div>
<div class="wrapper">
  <div class="attachment">
    <app-header *ngIf="isShowHeader"></app-header>

    <ng-container *ngIf="isShowRegistration$ | async; let additionalData">
      <app-registration [additionalData]="additionalData" *ngIf="additionalData.isShow"></app-registration>
    </ng-container>
    <!-- <div [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
    </div> -->
    <router-outlet #o="outlet"></router-outlet>
    <app-footer *ngIf="isShowFooter" style="margin-top: auto"></app-footer>
    <app-loader *ngIf="isShowLoader"></app-loader>
  </div>
</div>
<app-payments></app-payments>
