import { UiStateService } from './../comunication_services/uiStates.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ShopDataService } from '../comunication_services/shopData.service';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { GemsResponseData } from '../../types/gems-response-data.interface';
import { Observable } from 'rxjs';
import { PromoChatsPack } from '../../types/promo-chats-pack.interface';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ShopService {
    private _host: string = environment.apiUrl;

    constructor(
        private _http: HttpClient,
        private _shopDataService: ShopDataService,
        private _uiStateService: UiStateService,
    ) {}

    public getGems(): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            const requestUrl = `${this._host}/api/chatlot/gems`;
            const response = await this._http.get<GemsResponseData[]>(requestUrl).toPromise();
            this.saveGemsData(response);
            resolve(true);
            return true;
        });
    }

    public getChats(): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            const requestUrl = `${this._host}/api/chatlot`;
            const response = await this._http.get<GemsResponseData[]>(requestUrl).toPromise();
            this.saveChatsData(response);
            resolve(true);
            return true;
        });
    }

    public getPack(): Observable<PromoChatsPack[]> {
      const requestUrl = `${this._host}/api/Chatlot/chats-packs`;
      return this._http.get<any>(requestUrl).pipe(
        tap(res => {
          this.savePackData(res);
        })
      );
    }

    private saveGemsData(data: GemsResponseData[]) {
        this._shopDataService.changeAllGems(data);
    }

    private saveChatsData(data: GemsResponseData[]) {
        this._shopDataService.changeAllChats(data);
    }

    private savePackData(data: PromoChatsPack[]) {
        this._shopDataService.changePackData(data);
    }

    saveDiamonds(value: number): void {
        this._uiStateService.changeData(UiStatesEnum.diamonds, value);
    }


}
