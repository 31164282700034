import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class GalleryDataService {
    private defaultData = [];
    private data = [];
    public updateGalleryData = new BehaviorSubject(this.data);

    constructor() {
    }

    public changeData(fieldName: string, value: any): void {
        const defaultData = this.setNewData(this.data, fieldName, value);
        this.data = defaultData;
        this.updateGalleryData.next(defaultData);
    }

    public setNewData(obj: any, field: string, value: any): any {
        const setPath = (object: any, path: any, value: any) => path
            .split('.')
            .reduce((o: any, p: any, i: any) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object);
        setPath(obj, field, value);
        return obj;
    }

    public changeAllObject(value: any): void {
        this.data = value;
        this.updateGalleryData.next(this.data);
    }

    public changeDefaultData(): void {
        this.data = this.defaultData;
        this.updateGalleryData.next(this.data);
    }
}
