import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HeaderFooterService {
    private _host: string = environment.apiUrl;

    constructor() {}

    public isShowHeader = new BehaviorSubject(true);
    public isShowFooter = new BehaviorSubject(true);

    public changeHeaderVisibility(state: boolean): void{
        this.isShowHeader.next(state);
    }

    public changeFooterVisibility(state: boolean): void{
        this.isShowFooter.next(state);
    }
}
