import { UnbanPurchase } from './open-next-message.interface';
import { PromoBrand } from '../enums/chat-promo-brand.enum';
import { NextMessage } from './next-message.interface';

export enum ChatType {
  free = 'Free',
  real = 'Real',
  soft = 'Soft',
  promo = 'promo',
  discordLink = 'discordLink',
  trial = 'Trial'
}

export interface OpenChatData {
  type: ChatType;
  characterName: string;
  id: string;
  chatLotId: string;
  erogamesCost: number;
  promoCompany?: PromoBrand;
}

export interface TrialPurchaseData {
  cost: number;
  erogamesCost: number;
  productId: string;
};

export interface ChatData {
  characterAvatar: string;
  characterName: string;
  chatLotId?: string;
  cost?: number;
  deb?: number;
  erogamesCost?: number;
  hasAdditionalContent?: boolean;
  isAdditionalFinished?: boolean;
  isAdditionalPurchased?: boolean;
  isFeedbackSent?: boolean;
  isFinished?: boolean;
  isOffline?: boolean;
  isBanned?: boolean;
  createdAt: string;
  id: string;
  messages: NextMessage[];
  offlineSkipCost?: number;
  offlineTime?: number;
  newMessageCount?: number;
  modifiedAt: string;
  packId: [];
  status: string;
  users: null;
  trialTime?: number;
  trialPurchase?: TrialPurchaseData;
  feedbackFormShow?: boolean;
  promoWidget?: string;
  promoCompany?: PromoBrand;
  promoHasDetails?: boolean;
  promoDetailsText?: string;
  promoThumbnailPhotos?: string[];
  promoBanner?: PromoBrand;
  type?: ChatType;
  unbanPurchase?: UnbanPurchase;
}
