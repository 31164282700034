import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ChatData } from '../../types/chat-data.interface';
import { OpenNextMessage } from '../../types/open-next-message.interface';

@Injectable({
    providedIn: 'root',
})

export class ChatDataService {
    private defaultData: ChatData = {
        characterAvatar: null,
        characterName: '',
        createdAt: '',
        id: '',
        isOffline: false,
        messages: [],
        modifiedAt: '',
        packId: [],
        status: null,
        users: null,
        feedbackFormShow: false,
        unbanPurchase: null
    };

    private _isFullscreen: BehaviorSubject<boolean> = new BehaviorSubject(null);
    private data: ChatData;
    private blockData: OpenNextMessage = null;
    // Here is only messages array for chat needs
    public updateChatData: BehaviorSubject<ChatData>;
    // Here is all environment data like isOffline, isAdditionalContent and ctr...
    public updateBlockData = new Subject<OpenNextMessage>();

    get isFullscreen(): Observable<boolean> {
      return this._isFullscreen.asObservable();
    }

    constructor() {
      this.data = this.defaultData;
      this.updateChatData = new BehaviorSubject(this.data);
    }

    public setIsFullscreen(value: boolean) {
      this._isFullscreen.next(value);
    }

    public changeData(fieldName: string, value: any): void {
        const defaultData = this._setNewData(this.data, fieldName, value);
        this.data = defaultData;
        this.updateChatData.next(defaultData);
    }

    private _updateLastHeroMessage<T>(messageId: string, field: string, value: T) {
      const data = this.updateChatData.getValue();
      const updatedData = data.messages.map(message => {
        if(message.id === messageId){
          message[field] = value;
        }
        return message;
      });
      data.messages = updatedData;
      this.data = data;
      this.updateChatData.next(data);
    }

    public updateHeroLastMessageText(messageId: string, messageText: string) {
      this._updateLastHeroMessage<string>(messageId, 'messageText', messageText);
    }

    public updateHeroLastMessageEmoji(messageId: string, emoji: number) {
      this._updateLastHeroMessage<number>(messageId, 'emoji', emoji);
    }

    private _setNewData(obj: any, field: string, value: any): any {
        const setPath = (object: any, path: any, value: any) => path
            .split('.')
            .reduce((o: any, p: any, i: any) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object);

        setPath(obj, field, value);
        return obj;
    }

    public changeAllObject(value: any): void {
        this.updateChatData.next(value);
        this.data = value;
    }

    public changeBlockData(value: OpenNextMessage): void {
        this.blockData = value;
        this.updateBlockData.next(this.blockData);
    }

    public addMessage(message: any): void {
        const data = this.updateChatData.getValue();
        const messages = data.messages;
        // Avoid dublications
        if(messages && messages[messages.length -1]?.id !== message?.id){
          messages.push(message);
          this.data = data;
          this.updateChatData.next(data);
        }
    }

    public cleanData(): void{
        this.data = this.defaultData;
        this.updateChatData.next(this.data);
    }

    public updateSavedPhoto(id: string) {
      const data = this.updateChatData.getValue();
      data.messages.map(message => {
        if(message.id === id){
          message.isSave = true;
        }
        return message;
      });
      this.updateChatData.next(this.data);
    }
}
