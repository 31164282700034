import { ChatRatingBarModule } from './../chat-rating-bar/chat-rating-bar.module';
import { CircleDangerLevelModule } from './../circle-danger-level/circle-danger-level.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    CircleDangerLevelModule,
    ChatRatingBarModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
