import { FieldComponent } from './field/field.component';
import { CustomInputModule } from '../custom-input/custom-input.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegistrationComponent } from './registration.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationHelperService } from './registration-helper.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SocialLoginsComponent } from './social-logins/social-logins.component';


@NgModule({
    declarations: [
        RegistrationComponent,
        SignUpComponent,
        SignInComponent,
        FieldComponent,
        SocialLoginsComponent,
        ForgotPasswordComponent
    ],
    imports: [
        CommonModule,
        CustomInputModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    providers: [
      RegistrationHelperService
    ],
    exports: [
        RegistrationComponent
    ]
})
export class RegistrationModule { }
