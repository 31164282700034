import {
    Injectable,
} from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpEvent,
    HttpResponse,
} from '@angular/common/http';
import {
    throwError,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';
import { JWTTokenService } from '../services/comunication_services/JWTToken.service';

  @Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private _tokenService: JWTTokenService) {}

    intercept(req: HttpRequest < any >, next: HttpHandler) {
        const token = this._tokenService.getToken();
        req = req.clone({
            url: req.url,
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
        return next.handle(req).pipe(
            map((event: HttpEvent < any >) => event),
            catchError((error: HttpErrorResponse) => throwError(error)),
        );
    }
}
