import { IncomePayloadDataService } from './shared/services/income-payload-data.service';
import { AuthStateService } from './shared/services/auth/auth-state.service';
import { NativePushNotificationService } from './shared/services/notifications_services/native-push-notification.service';
import { PushNotificationService } from './shared/services/notifications_services/push-notification.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from './shared/services/comunication_services/loader.service';
import { NotificationService } from './shared/services/notifications_services/notification.service';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { HeaderFooterService } from './shared/services/comunication_services/headerFooter.service';
import { AnalyticsService } from './shared/services/analytics/analytics.service';
import { UnsubscribeHelper } from './shared/helpers/unsubscribe.helper';
// import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { SoundsService } from './shared/services/sounds.service';
import { AuthMethodsService } from './shared/services/auth/auth-methods.service';
import { WebPaymentService } from './shared/services/payments/web-payment.service';
import { CustomHeaderTextKey } from './shared/enums/custom-header-text-key.enum';
import { ShowRegistrationFormData } from './shared/services/auth/show-registration-form-data';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AnalyticsService, NotificationService, LoaderService],
  // animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {


  public isShowRegistration$: Observable<ShowRegistrationFormData>;
  public isShowHeader = false;
  public isShowFooter = false;
  public isShowLoader = false;
  public installPrompt = null;
  public isAllowRegistrationForm = environment.allowRegistrationForm;
  public showBg = environment.buildVersion !== 'legal';
  registrationCustomTextKey: CustomHeaderTextKey;
  registrationFormEnableClosing: boolean;

  subscribers: any[] = [];
  constructor(
    private _authMethodsService: AuthMethodsService,
    private _headerFooterService: HeaderFooterService,
    private _analyticsService: AnalyticsService,
    private _notificationService: NotificationService,
    private _loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private _unsubscribeHelper: UnsubscribeHelper,
    // private ym: NgxMetrikaService,
    private swUpdate: SwUpdate,
    private pushNotificationService: PushNotificationService,
    private _nativePushNotificationService: NativePushNotificationService,
    private _soundsService: SoundsService,
    private _authStateService: AuthStateService,
    private _webPaymentService: WebPaymentService,
    private _incomePayloadDataService: IncomePayloadDataService
  ) {

    // PWA update on changes
    this.swUpdate.available.subscribe(() => {
      if (confirm('A new version is available. Load it?')) {
        window.location.reload();
      }
    });

    this.isShowRegistration$ = this._authStateService.isShowRegistration$;

    this._headerFooterService.isShowHeader.subscribe((state) => {
      this.isShowFooter = state;
    });
    this._headerFooterService.isShowFooter.subscribe((state) => {
      this.isShowHeader = state;
    });
    this._loaderService.isLoader.subscribe((state) => {
      this.isShowLoader = state;
      if (state) {
        this.cdr.detectChanges();
      }
    });
    this._analyticsService.init();
  }

  ngOnInit() {

    this._notificationService.init();
    this._nativePushNotificationService.init();

    this.route.queryParams
      .subscribe(params => {
        if(environment.allowRegistrationForm){
          // Discord confirmation registration code
          if(params.code){
            this._authMethodsService.signUpWithDiscord(params.code).subscribe(
            res => {},
            error => {
              console.error(error);
            });
          }
          // Twitter confirmation token
          if(params.oauth_token && params.oauth_verifier){
            this._authMethodsService.signWithTwitter({
              oAuthToken: params.oauth_token,
              oAuthVerifier: params.oauth_verifier
            }).subscribe(
            res => {},
            error => {
              console.error(error);
            });
          }
        }
        this._incomePayloadDataService.getPayloadDataFromParams(params);

      });
    // this.ym.hit.emit();
    this._analyticsService.setPlatform();


    // Check if we have pending transaction
    // In Localstorage
    // And finish it
    this._webPaymentService.checkTransaction();
  }

  getInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
    });
  }

  askUserToInstallApp() {
    this.installPrompt.prompt();
  }

  ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this.subscribers);
    this._soundsService.destroy();
  }
}
