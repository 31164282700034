import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiStates } from '../../enums/uiStates';

@Injectable({
    providedIn: 'root',
})

export class BonuseDataService {

    private defaultData = [{
        dayNumber: 1,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 2,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 3,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 4,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 5,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 6,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 7,
        rewardType: 'diamant_chat',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    }];

    private data = [{
        dayNumber: 1,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 2,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 3,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 4,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 5,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 6,
        rewardType: 'diamant',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    },
    {
        dayNumber: 7,
        rewardType: 'diamant_chat',
        cost: 0,
        isCurrentDay: false,
        isClaimped: false,
        timeToNextReward: 0
    }];
    public updateBonusData = new BehaviorSubject(this.data);

    constructor() {
    }

    public changeData(fieldName: string, value: any): void {
        const defaultData = this.setNewData(this.data, fieldName, value);
        this.data = defaultData;
        this.updateBonusData.next(defaultData);
    }

    public setNewData(obj: any, field: string, value: any): any {
        const setPath = (object: any, path: any, value: any) => path
            .split('.')
            .reduce((o: any, p: any, i: any) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object);
        setPath(obj, field, value);
        return obj;
    }

    public setAllData(data: any): void {
        this.data = data;
        this.updateBonusData.next(data);
    }

    public cleanData(): void{
        this.data = this.defaultData;
        this.updateBonusData.next(this.data);
    }
}
