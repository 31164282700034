import { SwiperModule } from 'swiper/angular';
import { BannerSliderComponent } from './banner-slider.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';



@NgModule({
  declarations: [
    BannerSliderComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    DirectivesModule
  ],
  exports: [
    BannerSliderComponent
  ]
})
export class BannerSliderModule { }
