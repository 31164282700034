import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoGalleryComponent } from './photo-gallery.component';
import { SwiperModule } from 'swiper/angular';
import { IonicModule } from '@ionic/angular';
import { FullscreenModule } from '../fullscreen/fullscreen.module';



@NgModule({
  declarations: [
    PhotoGalleryComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    IonicModule,
    FullscreenModule,
    SwiperModule
  ],
  exports: [
    PhotoGalleryComponent
  ]
})
export class PhotoGalleryModule { }
