import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import {
  CanLoad
} from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard implements CanLoad {
  constructor(private _authService: AuthService) {}

  canLoad(route: Route): Observable<boolean>|Promise<boolean>|boolean {
    return this._authService.authorise().pipe(
      take(1)
    );
  }
}
