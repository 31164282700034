<ng-container>
  <div class="modal-overlay" id="modal-overlay"></div>
    <div class="modal-container animate-bottom">
      <div class="modal ">
        <div class="modal-head modal-head--light">
          How should we name you<br>in the game?
        </div>
        <div class="modal-body">
            <input #searchElement type="text" class="cozy-input form-control" [(ngModel)]="nickName">
            <button class="btn btn-primary" (click)="saveName()">
                Save
            </button>
        </div>
      </div>
    </div>

</ng-container>
