<div class="iframe-wrapper" *ngIf="isAllowPaymentIframe && showPaymentIframe">
  <button class="close close-iframe-btn" (click)="closePaymentsIframe()">
    <svg _ngcontent-ffg-c145="" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g _ngcontent-ffg-c145="" filter="url(#filter0_d5)">
        <rect _ngcontent-ffg-c145="" x="4" y="5.12134" width="3" height="25" rx="1.5" transform="rotate(-45 4 5.12134)" fill="currentColor"></rect>
        <rect _ngcontent-ffg-c145="" width="3" height="25" rx="1.5" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 24 5.12134)" fill="currentColor"></rect>
      </g>
      <defs _ngcontent-ffg-c145="">
        <filter _ngcontent-ffg-c145="" id="filter0_d5" x="0.621338" y="0.621338" width="26.7573" height="26.5563" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood _ngcontent-ffg-c145="" flood-opacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix _ngcontent-ffg-c145="" in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset _ngcontent-ffg-c145="" dy="1"></feOffset>
          <feGaussianBlur _ngcontent-ffg-c145="" stdDeviation="2"></feGaussianBlur>
          <feColorMatrix _ngcontent-ffg-c145="" type="matrix" values="0 0 0 0 1 0 0 0 0 0.333333 0 0 0 0 0.333333 0 0 0 0.4 0"></feColorMatrix>
          <feBlend _ngcontent-ffg-c145="" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend _ngcontent-ffg-c145="" mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  </button>
  <iframe style="border: none;" width="100%" height="100%" [src]="paymentUrl"></iframe>
</div>
