import { AuthMethodsService } from './../../../services/auth/auth-methods.service';
import { Platform } from '@ionic/angular';
import { Component, Input, OnDestroy } from '@angular/core';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { from, Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { NavHelper } from 'src/app/shared/helpers';
import { AuthApiService } from 'src/app/shared/services/auth/auth-api.service.ts.service';

@Component({
  selector: 'app-social-logins',
  templateUrl: './social-logins.component.html',
  styleUrls: ['./social-logins.component.scss'],
})
export class SocialLoginsComponent implements OnDestroy {
  @Input() place: 'login' | 'signup';
  googleErrorTxt = false;
  discordErrorTxt = false;
  twitterErrorTxt = false;
  userData: any = null;
  loginGoogleSubscription: Subscription = null;
  loginAndroidGoogleSubscription: Subscription = null;
  guestSubscription: Subscription = null;
  loadingAndroidGoogle = false;
  loadingGoogle = false;
  loadingGuest = false;
  loadingTwitter = false;
  constructor(
    private _authApiService: AuthApiService,
    private _authMethodsService: AuthMethodsService,
    private socialAuthService: SocialAuthService,
    private googlePlus: GooglePlus,
    public _platform: Platform,
    private _navHelper: NavHelper
  ) { }

  loginWithGoogle(): void {
    this.loadingGoogle = true;
    this.googleErrorTxt = false;
    this.loginGoogleSubscription = from(this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)).pipe(
      switchMap((res: SocialUser): Observable<boolean> => this._authMethodsService.signUpWithGoogle(res?.idToken)),
      take(1)
    ).subscribe(
      res => {
        this.loadingGoogle = false;
        this._navHelper.goToMain();
      },
      error => {
        this.loadingGoogle = false;
        this.googleErrorTxt = true;
      }
    );
  }

  loginAndroidWithGoogle() {
    if(this._platform.is('hybrid')){
      this.loadingAndroidGoogle = true;

    this.loginAndroidGoogleSubscription = from(this.googlePlus.login({
      webClientId: '422350541625-cad89bssaqtb2eda36sqm6fqaj3b1u26.apps.googleusercontent.com',
    })).pipe(
      switchMap((res: SocialUser): Observable<boolean> => this._authMethodsService.signUpWithGoogle(res?.idToken)),
      take(1)
    ).subscribe(
      res => {
        this.loadingAndroidGoogle = false;
        this._navHelper.goToMain();
      },
      error => {
        this.loadingAndroidGoogle = false;
        this.googleErrorTxt = true;
      }
    );
    }
  }

  goToDiscord(): void {
    const link = {
      url: 'https://discord.com/api/oauth2/authorize?',
      params: {
        client_id: '894944516644155482',
        response_type: 'code',
        scope: 'identify email',
        redirect_uri: window.location.origin
      }
    };
    const params = new URLSearchParams(link.params).toString();
    window.location.href = link.url + params;
  }


  goToTwitter(): void {
    this._authApiService.getTwitterAuthToken().subscribe(token => {
      window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${token}`;
    }, err => {
      this.twitterErrorTxt = true;
      this.loadingTwitter = false;
    });
  }


  ngOnDestroy() {
    this.loginAndroidGoogleSubscription?.unsubscribe();
    this.loginGoogleSubscription?.unsubscribe();
    this.guestSubscription?.unsubscribe();
  }

}
