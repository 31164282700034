<div class="rating-bar-container">
  <div class="hole-in-header"></div>
  <div class="hearts">

    <ng-lottie
      class="lottie-btn"
      (animationCreated)="heartAnimationCreate($event)"
      [options]="heartRateAnimationOptins"
      ></ng-lottie>
    <span>{{level}}</span>
    <div class="new-level-up-animation" *ngIf="levelUp">
      <ng-lottie
      class="lottie-btn"
      [options]="saveToGalleryAnimationOptions"
      ></ng-lottie>
    </div>
  </div>
  <div class="rating-bar-line">
    <div class="rating-bar-grey-line" [style.width]="(rating$ | async )+'%'">
      <div class="current-rating-display">{{ chatRating }}</div>
    </div>
  </div>
</div>
