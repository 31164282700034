import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenComponent } from './fullscreen.component';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from 'src/app/app.module';
import { SwiperModule } from 'swiper/angular';
import { IonicModule } from '@ionic/angular';
import { TutorialModule } from '../tutorial/tutorial.module';
@NgModule({
    declarations: [
        FullscreenComponent,
    ],
    imports: [
        CommonModule,
        LottieModule.forRoot({ player: playerFactory }),
        SwiperModule,
        IonicModule,
        TutorialModule
    ],
    exports: [
        FullscreenComponent,
    ],
    entryComponents: [FullscreenComponent],
    providers: []
})
export class FullscreenModule { }
