import { Injectable } from '@angular/core';
import { OpenNextMessage } from '../../types/open-next-message.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ChatByIdResponse } from '../../types/chat-by-id-response.interface';
import { GetAllChatsResponse } from '../../types/get-all-chats-response.interface';
import { catchError, tap } from 'rxjs/operators';

export interface ChooseAnswerResponse {
  chatRating: number;
  isBanned: boolean;
  messageId: string;
  emoji: number;
}

export interface UseGiftResponse {
  chatRating: number;
  ratingChange: number;
  chatLevel: number;
  chatLevelChange: number;
  coins: number;
}

export interface BuyChatByGemsResponse {
  coins: number;
  chatId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatApiMethodsService {
  private _host: string = environment.apiUrl;
  private _chooseAnswerInProgress = false;
  constructor(
    private _http: HttpClient
  ) {}

  public getNextMessagePackFromAPI(chatId: string): Observable<OpenNextMessage[]> {
    // Avoid overlaping this request to response-answer request. @TODO refactor on BE-FE
    if(!this._chooseAnswerInProgress){
      const requestUrl = `${this._host}/api/chats/dialogs/open-next-message`;
      return this._http.put<OpenNextMessage[]>(requestUrl, { chatId });
    } else {
      return of(null);
    }
  }

  public getChatById(chatId: string): Observable<ChatByIdResponse> {
    const requestUrl = `${this._host}/api/chats/${chatId}`;
    return this._http.get<ChatByIdResponse>(requestUrl);
  }

  public getAllChats(): Observable<GetAllChatsResponse> {
    const requestUrl = `${this._host}/api/chats`;
    return this._http
      .get<GetAllChatsResponse>(requestUrl);
  }

  public zeroingOffline(chatId: string): Observable<{coins: number}> {
    const requestUrl = `${this._host}/api/Chats/skip-offline`;
    return this._http.put<{coins: number}>(requestUrl, { chatId });
  }

  public buyAdditionalContent(chatId: string): Observable<{coins: number}> {
    const requestUrl = `${this._host}/api/Chats/buy-add-ons`;
    return this._http.post<{coins: number}>(requestUrl, { chatId });
  }

  public sendFeedbackForm(chatId: string, data): Observable<boolean> {
    const requestUrl = `${this._host}/api/Chats/${chatId}/feedback`;
    return this._http.post<any>(requestUrl, { chatId, ...data });
  }

  public chooseAnswer(chatId: string, answerId: number): Observable<ChooseAnswerResponse> {
    this._chooseAnswerInProgress = true;
    const requestUrl = `${this._host}/api/Chats/dialogs/choose-answer`;
    return this._http.put<ChooseAnswerResponse>(requestUrl, { chatId, answerId }).pipe(
      tap(res => {
        this._chooseAnswerInProgress = false;
      }),
      catchError((error: HttpErrorResponse) => {
        this._chooseAnswerInProgress = false;
        return throwError(error);
      })
    );
  }

  public saveToGalery(
    chatId: string,
    url: string,
    contentType: string,
    messageId: string
  ): Observable<{coins: number}> {
    const requestUrl = `${this._host}/api/user/gallery`;
    // COST is rudiment. Kill on Server side
    return this._http.put<{coins: number}>(requestUrl, { chatId, url, cost: 0, contentType, messageId });
  }


  public useGift(giftId: string, chatId: string): Observable<UseGiftResponse> {
    const requestUrl = `${this._host}/api/Chats/use-gift`;
    return this._http.put<UseGiftResponse>(requestUrl, { chatId, giftId });
  }

  public buyChatByGems(chatId: string): Observable<BuyChatByGemsResponse>  {
    const requestUrl = `${this._host}/api/Chats/buy-soft-chat`;
    return this._http.put<BuyChatByGemsResponse>(requestUrl, { chatId });
  }

  public unbanChat(chatId: string): Observable<BuyChatByGemsResponse>  {
    const requestUrl = `${this._host}/api/Chats/unban`;
    return this._http.put<BuyChatByGemsResponse>(requestUrl, { chatId });
  }
}
