import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SoundsEnum } from '../enums/sounds.enum';
import { SettingsDataService } from './comunication_services/settingsData.sevice';
import {NativeAudio} from '@capacitor-community/native-audio';

const sounds = {
  [SoundsEnum.coins]: 'coins.mp3',
  // [SoundsEnum.ping1]: 'ping1.mp3',
  //[SoundsEnum.ping2]: 'ping2.mp3',
  // [SoundsEnum.ping3]: 'ping3.mp3',
  //[SoundsEnum.ping4]: 'ping4.mp3',
  [SoundsEnum.bonus1]: 'bonus-1.mp3',
  [SoundsEnum.bonus2]: 'bonus-2.mp3',
  // [SoundsEnum.click1]: 'click-1.mp3',
  // [SoundsEnum.click2]: 'click-2.mp3',
  // [SoundsEnum.fingerScroll]: 'finger-scroll.mp3',
  // [SoundsEnum.ok1]: 'ok-1.mp3',
  // [SoundsEnum.ok2]: 'ok-2.mp3',
  [SoundsEnum.typing1]: 'typing1.mp3',
  // [SoundsEnum.accessDenied1]: 'access-denied-1.mp3',
  // [SoundsEnum.accessDenied2]: 'access-denied-2.mp3',
  [SoundsEnum.sentMessage1]: 'sent-message-1.mp3',
  [SoundsEnum.sentMessage2]: 'sent-message-2.mp3',
  // [SoundsEnum.tick1]: 'tick-1.mp3',
};

@Injectable({
  providedIn: 'root'
})
export class SoundsService {

  pathToSound = 'public/assets/audio/';
  soundsOn: boolean;

  constructor(
    private _platform: Platform,
    private _settingsDataService: SettingsDataService
    ) {
    _platform.ready().then(() => {
      this.init();
    });
    }

    init() {
      if(!this._platform.is('hybrid')) {
        return;
      }
      Object.entries(sounds).forEach(sound => {
        NativeAudio.preload({
          assetId: sound[0],
          assetPath: `${this.pathToSound}${sound[1]}`
          // audioChannelNum: 1,
          // isUrl: false
        });
      });

      this._settingsDataService.updateSettingsData.subscribe(res => {
        this.soundsOn = res.sound;
      });
    }

    destroy() {
      Object.entries(sounds).forEach(sound => {
        NativeAudio.unload({
          assetId: sound[0]
        });
      });
    }

    play(sound: SoundsEnum, loop = false){
      if(!this._platform.is('hybrid')) {
        return;
      }
      if(this.soundsOn){
        NativeAudio[loop ? 'loop' : 'play']({
          assetId: sound.toString(),
          time: 6
        });
      }
    }

    stop(sound: SoundsEnum){
      if(!this._platform.is('hybrid')) {
        return;
      }
      NativeAudio.stop({
        assetId: sound.toString()
      });
    }
}
