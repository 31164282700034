import { PromoBrand } from './../../shared/enums/chat-promo-brand.enum';
import { GameSections, PromoAnalyticsData } from './../../shared/types/show-promo-analytics-options.interface';
import { ProfileService } from './../../shared/services/API_services/profile.service';
import { UiStateService } from 'src/app/shared/services/comunication_services/uiStates.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiStates } from 'src/app/shared/enums/uiStates';
import { AlbumData } from 'src/app/shared/types/album-data.interface';
import { ProfileData } from 'src/app/shared/types/profile-data.interface';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { UiStatesEnum } from 'src/app/shared/enums/ui-states.enum';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {


  data: ProfileData;
  rating: {
    girl: number;
    story: number;
  } = {
    girl: 0,
    story: 0
  };
  albumData: AlbumData;
  subscription: Subscription;
  promoBrand = PromoBrand;
  constructor(
    private _uiStateService: UiStateService,
    private _profileService: ProfileService,
    private route: ActivatedRoute,
    private _analyticsService: AnalyticsService,
  ) { }

  getRating(rating): number {
    const coeff = 0.5;
    const r = !isNaN(rating) ? Number(rating) : 0;
    return ((r + coeff) <= 5) ? (r + coeff) : 5;
  }

  ngOnInit() {
    this._uiStateService.changeData(UiStatesEnum.state, UiStates.profile);
    this.subscription = this.route.params.pipe(
      switchMap(params => this._profileService.getData(params.id))
    ).subscribe((res: ProfileData) => {
      this._uiStateService.changeData(UiStatesEnum.chatId, res.chatId);
      this.data = res;
      this.rating = {
        girl: this.getRating(res.girlRating),
        story: this.getRating(res.storyRating)
      };
      this.albumData = {
        id: res.chatId,
        name: res.name,
        contentData: res.contentData,
        countContent: res.contentData.length
      };
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  clickOnDiscord() {
    this._analyticsService.clickOnDiscordLink('profile');
  }

  clickOnLinkInBio(promo: PromoBrand, placeInSection: string) {
    this._analyticsService.clickOnPromoWidget({
      promo,
      section: GameSections.profile,
      placeInSection,
      chatName: this.data.name
    });
  }

  getShowLinkAnalyticOptions(promo: PromoBrand, placeInSection: string): PromoAnalyticsData {
    return {
      promo,
      section: GameSections.profile,
      placeInSection,
      chatName: this.data.name
    };
  }

}
