import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor(
  ) { }

  public randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

  // TODO write any often-use tool
}
