import { LottieModule } from 'ngx-lottie';
import { PaymentAnimatedStatesComponent } from './payment-animated-states/payment-animated-states.component';
import { PaymentsComponent } from './payments.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentIframeComponent } from './payment-iframe/payment-iframe.component';
import { ErogamesNotEnoughMoneyPopupComponent }
  from './erogames-modals/erogames-not-enough-money-popup/erogames-not-enough-money-popup.component';
import { ErogamesPurchaseConfirmComponent }
  from './erogames-modals/erogames-purchase-confirm/erogames-purchase-confirm.component';



@NgModule({
  declarations: [
    PaymentsComponent,
    PaymentIframeComponent,
    PaymentAnimatedStatesComponent,
    ErogamesNotEnoughMoneyPopupComponent,
    ErogamesPurchaseConfirmComponent
  ],
  imports: [
    CommonModule,
    LottieModule
  ],
  exports: [
    PaymentsComponent,
    PaymentIframeComponent,
    PaymentAnimatedStatesComponent,
    ErogamesNotEnoughMoneyPopupComponent,
    ErogamesPurchaseConfirmComponent
  ]
})
export class PaymentsModule { }
