import { Platform } from '@ionic/angular';
import { PurchaseData } from './../../types/purchase-data.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PaymentsStateService } from './payments-state.service';
import { PurchasePackData } from '../../types/purchase-pack-data-interface';
import { PaymentAnimationStates } from '../../enums/payment-animation-states';
import { LocalStorageService } from '../comunication_services/localStorage.service';
import { PaymentResponse } from '../../types/payment-response.interface';
// import { Browser } from '@capacitor/browser';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics/analytics.service';
import { ShopService } from '../API_services/shop.service';
import { PaymentResponeStatus } from '../../enums/payment-respone-status.enum';
import { AuthStateService } from '../auth/auth-state.service';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';

@Injectable({
  providedIn: 'root',
})
export class WebPaymentService {
  purchaseViaIframeAction: BehaviorSubject<PaymentResponse> =
    new BehaviorSubject(null);
  private _host: string = environment.apiUrl;
  constructor(
    private _http: HttpClient,
    private _platform: Platform,
    private _localStorageService: LocalStorageService,
    private _paymentsStateService: PaymentsStateService,
    private _analyticsService: AnalyticsService,
    private _shopService: ShopService,
    private _authStateService: AuthStateService
  ) {}

  purchase(data: PurchaseData ): Observable<boolean> {
    return this._purchase(data.id, data.source, {}, false, data.chatId);
  }

  purchasePack(data: PurchasePackData): Observable<boolean> {
    return this._purchase('', null, data.pack, true);
  }

  private _purchase(
    id: string,
    source: PurchaseItemType,
    pack = {},
    isPack = false,
    chatId = ''
  ): Observable<boolean> {
    this._paymentsStateService.paymentState = PaymentAnimationStates.progress;

    return this._getPurchaseDataFromAPI(source, isPack, id, pack, chatId).pipe(
      map((res) => {
        this._localStorageService.setTransactionId(res.payment.transactionId);
        setTimeout(() => {
          // To avoid permanent loader due any error with iframes and inappbrowsers
          this._paymentsStateService.paymentState = null;
        }, 5000);
        const isApk = this._platform.is('hybrid');
        if (isApk) {
          // this._openInInappBrowser(res);
          this.openInCurrentWindow(res);
          return true;
        } else {
          this.openInCurrentWindow(res); // Open in current window for Segpay
          // this._openInIframe(res); // Open in iframe for Centrobill
          return true;
        }
      }),
      catchError(err => {
        if(err.status === 403) {
          this._authStateService.showRegistrationForm({
            isShow: true,
            data: {
              placeWereCalled: 'At purchase: ' + id,
              enableClosing: true
            }
          });
          this._paymentsStateService.paymentState = null;
        } else {
          this._paymentsStateService.paymentState = PaymentAnimationStates.error;
        }
        throw err;
      })
    );
  }

  private openInCurrentWindow(res: PaymentResponse) {
    window.location.href = res.payment.url;
  }

  private _getPurchaseDataFromAPI(
    source: PurchaseItemType,
    isPack = false,
    productId: string = null,
    chatPackage: any = {},
    chatId = ''
  ): Observable<PaymentResponse> {
    const requestUrl = `${this._host}/api/Shop/buy`;
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    });
    // const str = 'cc';
    const str = 'seqpay';
    // If android - we need redirect. If no - we just close iframe without redirect. TODO investigate and optimize.
    // But Not for segpay
    // const url = this._platform.is('android') ? `${window.location.origin}${window.location.pathname}` : null;
    const url = `${window.location.origin}${window.location.pathname}`;

    const data = {
      paymentMethod: str,
      redirectUrl: url,
    };
    const bodyData = {
      ...data,
      chatPackage,
      productId,
      chatId,
      source
    };

    const body = JSON.stringify(bodyData);

    return this._http
      .post<PaymentResponse>(requestUrl, body, {
        headers,
      })
      .pipe(take(1));
  }

  checkTransaction(): void {
    const id = this._localStorageService.getTransactionId();

    if (!id) {
      this._localStorageService.removeTransactionId();
      return;
    }

    this._paymentsStateService.paymentState = PaymentAnimationStates.progress;
    const requestUrl = `${this._host}/api/Shop/${id}`;
    this._http
      .get<any>(requestUrl)
      .pipe(
        take(1)
      ).subscribe((res) => {
          if (res?.payment.status === PaymentResponeStatus.completed || res?.payment.status === PaymentResponeStatus.success) {
            this._localStorageService.removeTransactionId();
            this._analyticsService.payment(
              res?.payment?.platform,
              res?.payment?.id,
              res?.payment?.cost,
              res?.payment?.productId,
              res?.payment?.lotType,
              res?.payment?.source,
              res?.payment?.meta,
            );
            this._shopService.saveDiamonds(res?.coins);
            this._paymentsStateService.paymentState = PaymentAnimationStates.success;
            // this._navHelper.goToMain();
          } else if (res?.payment.status === PaymentResponeStatus.redirect){
            this._paymentsStateService.paymentState = null;
            this._localStorageService.removeTransactionId();
            return false;
          } else {
            this._paymentsStateService.paymentState = PaymentAnimationStates.error;
            return false;
          }
        }, err => {
          this._paymentsStateService.paymentState = PaymentAnimationStates.error;
        }
      );
  }
}
