import { MatchModalComponent } from './match-modal/match-modal.component';
import { LottieModule } from 'ngx-lottie';
import { RewardsReceivedModalComponent } from './rewards-received-modal/rewards-received-modal.component';
import { UpdateVersionModalComponent } from './update-version-modal/update-version-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterNicknameModalComponent } from './enter-nickname-modal/enter-nickname-modal.component';
import { FormsModule } from '@angular/forms';
import { TrialPurchaseModalComponent } from './trial-purchase-modal/trial-purchase-modal.component';
import { ChatDetailsModalComponent } from './chat-details-modal/chat-details-modal.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    UpdateVersionModalComponent,
    RewardsReceivedModalComponent,
    EnterNicknameModalComponent,
    MatchModalComponent,
    TrialPurchaseModalComponent,
    ChatDetailsModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LottieModule,
    IonicModule
  ],
  exports: [
    UpdateVersionModalComponent,
    RewardsReceivedModalComponent,
    EnterNicknameModalComponent,
    MatchModalComponent,
    TrialPurchaseModalComponent,
    ChatDetailsModalComponent
  ]
})
export class MiscAlertsModule { }
