export enum PromoBrand {
  stripchat = 'Stripchat',
  onlyfans = 'Onlyfans',
  datify = 'Datify',
  discord = 'Discord',
  bannerSlider = 'Banner slider',
  instagram = 'Instagram',
  myclub = 'Myclub',
  faphouse ='Faphouse'
}
