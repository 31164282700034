import { NextBonusTimeService } from './../services/comunication_services/nextBonusTime.service';
import { Injectable } from '@angular/core';
import { BonuseDataService } from '../services/comunication_services/bonusData.service';
import { ChatDataService } from '../services/comunication_services/chatData.service';
import { SettingsDataService } from '../services/comunication_services/settingsData.sevice';
import { TutorialService } from '../services/comunication_services/tutorial.service';

@Injectable({
    providedIn: 'root',
})
export class Clean {
    constructor(
        private _chatDataService: ChatDataService,
        private _bonuseDataService: BonuseDataService,
        private _nextBonusTimeService: NextBonusTimeService,
        private _settingsDataService: SettingsDataService,
        private _tutorialService: TutorialService
    ) {}

    public cleanAllData(): void {
        this._chatDataService.cleanData();
        this._bonuseDataService.cleanData();
        this._nextBonusTimeService.setDefaultData();
        this._settingsDataService.cleanData();
        this._tutorialService.cleanData();
    }

    public cleanSofData(): void {
      this._bonuseDataService.cleanData();
      this._nextBonusTimeService.setDefaultData();
      this._tutorialService.cleanData();
  }

}
