const settings = {
    gameServerUrl: '',
    domainName: '',
    isGuest: '',
    goToId: '',
    messageAwait: 0,
    isCanGetNewMessage: true,
    platform: 'ios', //'ios', 'android', 'web',
    quickMessaging: false
};

export default settings;
