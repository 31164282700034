import {
	Component, EventEmitter, OnDestroy, OnInit, Output,
} from '@angular/core';
import {
	FormBuilder, FormGroup,
} from '@angular/forms';

import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { FormField } from 'src/app/shared/types/form-field.interface';
import { FormClassNameEnum } from 'src/app/shared/enums/form-class-name.enum';
import { FormFieldTypeEnum } from 'src/app/shared/enums/form-field-type.enum';
import { RegistrationHelperService } from '../registration-helper.service';
import { Subscription } from 'rxjs';
import { AuthMethodsService } from 'src/app/shared/services/auth/auth-methods.service';


@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy{
	@Output() signUpButton = new EventEmitter();
	@Output() forgotPasswordButton = new EventEmitter();
	public form: FormGroup;
  socialUser: SocialUser;
  isLoggedin: boolean;
  loading = false;
  signInSubscription: Subscription = null;
  commonServerErrors: string[] = [];
  formArray: FormField[] = [
    {
      className: FormClassNameEnum.formGroup,
      fieldType: FormFieldTypeEnum.email,
      placeHolder:'Email',
      controlName: 'mail',
      validators: {
        required: true,
        email: true
      }
    },
    {
      className: FormClassNameEnum.formGroup,
      fieldType: FormFieldTypeEnum.password,
      placeHolder:'Password',
      controlName: 'password',
      validators: {
        required: true
      }
    }
  ];

	constructor(
    private formBuilder: FormBuilder,
    private _authMethodsService: AuthMethodsService,
    private socialAuthService: SocialAuthService,
    public registrationHelperService: RegistrationHelperService,
) {
  this.form = this.formBuilder.group({});
  this.formArray.forEach( (item: FormField) => {
    const validators = this.registrationHelperService.setValidators(item.validators);
    this.form.addControl(item.controlName, this.formBuilder.control('', validators));
  });
}

ngOnInit() {
  this.socialAuthService.authState.subscribe((user) => {
    this.socialUser = user;
    this.isLoggedin = (user != null);
  });
}

	public async singIn(): Promise<void> {
    this.loading = true;
		const { form } = this;
		const { value } = form;
		if (form.valid) {
			this.signInSubscription = this._authMethodsService.signIn(value.mail, value.password).subscribe(res => {
        this.loading = false;
        // this._navHelper.goToMain();
      }, err => {
        this.commonServerErrors = this.registrationHelperService.setServerErrors(err, this.form);
        this.loading = false;
      });
		} else {
      this.loading = false;
			Object.keys(form.controls).forEach((field) => {
				const control = form.controls[field];
				if (!control.valid || control.value.lenght) {
					control.markAsTouched({ onlySelf: true });
				}
			});
		}
	}

	public goToSignUp(): void {
		this.signUpButton.emit();
	}

  public goToForgotPassword(): void{
    this.forgotPasswordButton.emit();
  }

  ngOnDestroy() {
    this.signInSubscription?.unsubscribe();
  }

}
