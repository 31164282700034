<ion-content fullscreen>
  <swiper
    [keyboard]="true"
    [pagination]="true"
    [zoom]="true"
    [config]="config"
    [initialSlide]="startWith"
  >
    <ng-template
      swiperSlide
      *ngFor="let item of this.contentData; let i = index"
    >
      <div class="img-box" *ngIf="this.item.contentType === 'Image'">
        <div class="tob-bar-photo">
          <button class="arrow-prev" aria-label="prev" (click)="out()"></button>
        </div>
        <div class="swiper-zoom-container">
          <div *ngIf="false" class="girl-img" [style.background-image]="'url(' + this.item.url + ')'"></div>
          <img  [src]="this.item.url" alt="" class="girl-img">
        </div>
        <div class="bottom-bar-photo" *ngIf="!isGallery">
          <ng-lottie
            class="lottie-btn"
            [options]="saveToGalleryAnimationOptions"
            (animationCreated)="animationCreated($event)"
            *ngIf="this.item.isSave"
          ></ng-lottie>
          <button
            type="button"
            class="btn btn-primary"
            *ngIf="!this.item.isSave"
            (click)="this.buy(item)"
          >
            Save to gallery
          </button>
        </div>
      </div>

      <div class="prev-video" *ngIf="item.contentType === 'Video'">
        <div class="tob-bar-photo">
          <button class="arrow-prev" aria-label="prev" (click)="out()"></button>
        </div>
        <div id="video-container">
          <div style="position: relative">
            <video

              loop
              class="fullscreen-video"
              [attr.src]="item.url + '#t=0.001'"
              disablepictureinpicture
              controlslist="nodownload"
              poster="https://images.unsplash.com/photo-1545486332-9e0999c535b2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmxhY2t8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80"
              [class.blur]="this.item.isHide && !this.item.isSave"
              id="video"
              #videos
              (click)="playPause(item.url)"
            >
              Sorry, your browser doesn't support embedded videos, but don't
              worry, you can download it and watch it with your favorite video
              player!
            </video>
            <div class="btn-play">
              <button
                type="button"
                #playButton
                style="background: transparent; border: none"
                (click)="playPause(item.url)"
                *ngIf="!isPlay"
                aria-label="play video"
              >
                <img src="assets/img/play-btn.png" alt="" />
              </button>
              <button
                type="button"
                #playButton
                (click)="playPause(item.url)"
                style="background: transparent; border: none"
                *ngIf="isPlay && isPlayVisible"
                aria-label="play pause"
              >
                <img src="assets/img/Pause.png" alt="" />
              </button>
            </div>
          </div>
          <div class="video-control-wrap">
            <input
              type="range"
              class="slider"
              [ngStyle]="{ background: inputStyle }"
              #seekBars
              value="0"
            />
            <div class="top" style="margin-top: 10px;">
              <div class="mute-btn">
                <button
                  type="button"
                  class=""
                  style="background: transparent; border: none"
                  #muteButton
                  (click)="mute(item.url)"
                  *ngIf="!isMute"
                >
                  <svg
                    width="17"
                    height="15"
                    viewBox="0 0 17 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.41126 14.5481C9.74241 14.3928 9.95402 14.0601 9.95402 13.6939L9.95424 0.944475C9.95424 0.578464 9.74241 0.245524 9.41148 0.0902259C9.08056 -0.066189 8.68952 -0.0163596 8.40753 0.217369L3.69318 3.82385H0.944076C0.422767 3.82385 0 4.24662 0 4.76815V9.87018C0 10.3915 0.422767 10.8145 0.944076 10.814H3.69296L8.4073 14.4205C8.58003 14.5637 8.79387 14.6379 9.00972 14.6379C9.14603 14.6379 9.28323 14.6087 9.41126 14.5481ZM11.8786 6.70711C11.4881 6.3166 11.4881 5.68343 11.8786 5.29292C12.2691 4.90236 12.9023 4.90236 13.2928 5.29292L13.9999 6.00008L14.7071 5.29292C15.0976 4.90242 15.7307 4.90242 16.1212 5.29292C16.5118 5.68349 16.5118 6.3166 16.1212 6.70717L15.4142 7.41426L16.1212 8.12135C16.5117 8.51186 16.5117 9.14503 16.1212 9.53554C15.7307 9.9261 15.0975 9.9261 14.707 9.53554L13.9999 8.82851L13.2928 9.5356C12.9023 9.9261 12.2691 9.9261 11.8786 9.5356C11.4881 9.14503 11.4881 8.51192 11.8786 8.12135L12.5857 7.41426L11.8786 6.70711Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  style="background: transparent; border: none"
                  #muteButton
                  (click)="mute(item.url)"
                  *ngIf="isMute"
                >
                  <svg
                    width="21"
                    height="17"
                    viewBox="0 0 21 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.95402 14.7379C9.95402 15.1042 9.74241 15.4369 9.41126 15.5922C9.28323 15.6527 9.14603 15.682 9.00972 15.682C8.79387 15.682 8.58003 15.6078 8.4073 15.4646L3.69296 11.8581H0.944076C0.422767 11.8586 0 11.4356 0 10.9143V5.81223C0 5.29069 0.422767 4.86793 0.944076 4.86793H3.69318L8.40753 1.26145C8.68952 1.02772 9.08056 0.977887 9.41148 1.1343C9.74241 1.2896 9.95424 1.62254 9.95424 1.98855L9.95402 14.7379ZM13.5822 13.2627C13.5592 13.2643 13.537 13.2652 13.5142 13.2652C13.2649 13.2652 13.0244 13.1666 12.8468 12.9888L12.7206 12.8621C12.3894 12.5316 12.3505 12.008 12.6294 11.6322C13.3364 10.679 13.7095 9.54898 13.7095 8.36358C13.7095 7.08857 13.2859 5.89267 12.4841 4.90502C12.1789 4.52962 12.2071 3.98441 12.5492 3.64253L12.6752 3.51628C12.8638 3.32769 13.1165 3.2249 13.39 3.24121C13.6564 3.25462 13.9051 3.37997 14.074 3.58644C15.1861 4.94725 15.7736 6.59944 15.7736 8.3638C15.7736 10.007 15.254 11.5712 14.2708 12.8864C14.1066 13.1056 13.8555 13.2433 13.5822 13.2627ZM17.4854 16.1803C17.3147 16.3821 17.0678 16.5032 16.8034 16.5144C16.7905 16.5148 16.7773 16.5153 16.7639 16.5153C16.5138 16.5153 16.2739 16.4165 16.0962 16.2388L15.9722 16.1148C15.6256 15.7685 15.6022 15.2145 15.9172 14.8396C17.4398 13.0288 18.2786 10.729 18.2786 8.36358C18.2786 5.90317 17.3806 3.5346 15.7505 1.69427C15.4201 1.32066 15.4368 0.755108 15.7885 0.402057L15.9123 0.278042C16.096 0.0934728 16.3346 -0.00819685 16.6084 0.000517696C16.8682 0.00789154 17.114 0.122745 17.2868 0.317146C19.2571 2.53556 20.3424 5.39348 20.3424 8.36358C20.3429 11.2213 19.3282 13.9974 17.4854 16.1803Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>

              <!-- <span
                  style="color: white"
                  *ngIf="video.currentTime > 0"
                  class="video-current"
                  >{{ this.timeConverter.toMinSec(video.currentTime) }}/{{
                    this.timeConverter.toMinSec(video.duration)
                  }}</span -->
              >
            </div>
          </div>
          <div class="bottom-bar-photo" *ngIf="!isGallery">
            <button
              type="button"
              class="btn btn-primary"
              *ngIf="!this.item.isSave"
              (click)="this.buy(item)"
            >
              Save to Gallery
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</ion-content>
<app-tutorial [parent]="this"></app-tutorial>
