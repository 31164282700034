import { SetNicknameService } from '../set-nickname.service';
import { SplitTestCase, SplitTestCasesService } from './../split-test-cases.service';
import { AuthErogamesService } from './auth-erogames.service.ts.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NavHelper } from '../../helpers/nav.helper';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { UiStateService } from '../comunication_services/uiStates.service';
import { SettingsDataService } from '../comunication_services/settingsData.sevice';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics/analytics.service';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { AuthStatus } from '../../enums/auth-status.enum';
import { AuthApiService } from './auth-api.service.ts.service';
import { AuthStateService } from './auth-state.service';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { CheckUpdatesService } from '../check-updates.service';
import { AuthMethodsService } from './auth-methods.service';
import { UserMyResponse } from '../../types/user-my-response.interface';
// import ErogamesAuth from '../../plugins/erogames-auth';
// declare const eroAuth;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isHaveData = false;

  constructor(
    private _tokenService: JWTTokenService,
    private _navHelper: NavHelper,
    private _uiStateService: UiStateService,
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService,
    private _authApiService: AuthApiService,
    private _authStateService: AuthStateService,
    private _authErogamesService: AuthErogamesService,
    private _checkUpdateService: CheckUpdatesService,
    private _authMethodsService: AuthMethodsService,
    private _splitTestCasesService: SplitTestCasesService,
    private _setNicknameService: SetNicknameService
  ) {

    if (environment.buildVersion === 'erogames') {
      this._tokenService.erogamesToken = null;
    }

    this._settingsDataService.updateSettingsData.subscribe((res) => {
      if (res.id) {
        this.isHaveData = true;
      } else {
        this.isHaveData = false;
      }
    });
  }


  public getUser(getAnyway: boolean = false): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (!this.isHaveData) {
        this._authApiService
          .getUser()
          .pipe(
            take(1)
          )
          .subscribe(
            (response) => {
              this.saveUserData(response);

              this._splitTestCasesService.init(response.splitTests);
              this._setNicknameService.init(response.isNickNameSet);

              this._checkUpdateService.checkUpdates(response);
              this._authStateService.isGuest = response.isGuest;
              this._analyticsService.setIsGuest(response.isGuest);
              resolve(true);
            },
            (err) => {
              resolve(false);
            }
          );
      } else {
        resolve(true);
      }
    });
  }

  private saveUserData(data: UserMyResponse) {
    this._settingsDataService.changeAllObject({
      avatar: data.avatar,
      email: data.email,
      id: data.id,
      music: data.music,
      nickName: data.nickName,
      sound: data.sound,
    });
    this._settingsDataService.clientId = data.id;
    this._analyticsService.setUserId(data.id);
    this._uiStateService.changeData(UiStatesEnum.diamonds, data.coins);
  }

  ///////////////

  private _changeRegistrationVisibility(isShow: boolean): void {
    if (environment.allowRegistrationForm) {

      if(isShow) {
        this._authStateService.showRegistrationForm({
          isShow: true,
          data: {
            placeWereCalled: 'Auth At Start',
            enableClosing: false
          }
        });
      } else {
        this._authStateService.hideRegistrationForm();
      }
    }
  }

  private _isAuthorisedByBuildVersion(): boolean {
    switch (environment.buildVersion) {
      case 'erogames':
        return !!this._tokenService.erogamesToken;
      case 'legal':
        return true;
      case 'nutaku':
      case 'default':
        return !!(this._tokenService.getUserEmail() && !this._tokenService.isTokenExpired());
    }
  }

  private _showRegform() {
    this._navHelper.goToLaunch();
    if (localStorage.getItem('read_tncs')) {
      this._changeRegistrationVisibility(true);
    }
  }

  private _tryAsGuestABTestOrShowRegform() {
    this._authMethodsService.trySignAsGuest().pipe(
      catchError(() => {
        this._showRegform();
        return of(null);
      })
    ).subscribe();
  }

  private authenticationProcess(): Observable<boolean> {

    switch (environment.buildVersion) {
      case 'erogames':
        this._authErogamesService.erogamesTokenUpdateOnRefresh();
        break;
      case 'legal':
        break;
      case 'nutaku':
      case 'default':
        this._tryAsGuestABTestOrShowRegform();
        break;
    }

    return this._authStateService.authStatus$.pipe(
      filter(res => res === AuthStatus.authenticated),
      map(() => true),
      take(1)
    );
  }

  authorise(): Observable<any> {
    return this._authStateService.authStatus$.pipe(
      filter(res => res !== AuthStatus.inProgress),
      take(1),
      switchMap(res => {
        if(res === AuthStatus.authenticated) {
          return of(true);
        } else if(this._isAuthorisedByBuildVersion()){
          this._authStateService.authStatus = AuthStatus.authenticated;
          return of(true);
        } else {
          this._authStateService.authStatus = AuthStatus.inProgress;
          return this.authenticationProcess();
        }
      })
    );
  }
}
