import { TutorialComponent } from './tutorial.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialService } from '../../services/comunication_services/tutorial.service';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from 'src/app/app.module';



@NgModule({
  declarations: [
    TutorialComponent
  ],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [
    TutorialComponent
  ]
})
export class TutorialModule { }
