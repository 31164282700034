<div class="description-text">
  Or sign in with Google and save your progress on cloud
</div>

<div class="form-group" *ngIf="_platform.is('android')">
  <button
    [class.spinner]="loadingAndroidGoogle"
    [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle"
    type="button"
    (click)="loginAndroidWithGoogle()"
    class="btn btn-block btn-google"
    >
    <ng-container *ngIf="place === 'signup'">Sign up with Google</ng-container>
    <ng-container *ngIf="place === 'login'">Login with Google</ng-container>

  </button>
  <div *ngIf="googleErrorTxt" class="validation-error-block">Something went wrong.</div>
</div>

<div class="form-group" *ngIf="!_platform.is('android')">
  <button
    [class.spinner]="loadingGoogle"
    [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle"
    type="button"
    (click)="loginWithGoogle()"
    class="btn btn-block btn-google"
    >
    <ng-container *ngIf="place === 'signup'">Sign up with Google</ng-container>
    <ng-container *ngIf="place === 'login'">Login with Google</ng-container>
  </button>
  <div *ngIf="googleErrorTxt" class="validation-error-block">Something went wrong.</div>
</div>

<div class="form-group">
  <button
    [class.spinner]="loadingTwitter"
    [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle || loadingTwitter"
    type="button"
    (click)="goToTwitter()"
    class="btn btn-block btn-twitter"
    >
    <ng-container *ngIf="place === 'signup'">Sign up with Twitter</ng-container>
    <ng-container *ngIf="place === 'login'">Login with Twitter</ng-container>
  </button>
  <div *ngIf="twitterErrorTxt" class="validation-error-block">Something went wrong.</div>
</div>

<div class="form-group">
  <button
   type="button"
   [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle"
   (click)="goToDiscord()"
   class="btn btn-block btn-discord"
   >
   <ng-container *ngIf="place === 'signup'">Sign up with Discord</ng-container>
    <ng-container *ngIf="place === 'login'">Login with Discord</ng-container>
  </button>
  <div *ngIf="discordErrorTxt" class="validation-error-block">Something went wrong.</div>
</div>

<!-- <div class="form-offset">
  <button
    [class.spinner]="loadingGuest"
    [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle"
    class="btn btn-grey btn-block btn-uppercase"
    (click)="guestEntry()"
    >
    Stay as guest
  </button>
</div> -->
