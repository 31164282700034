import { TimeConverter } from './../../helpers/timeConverter';
import { GalleryService } from './../../services/API_services/gallery.service';
import { AlbumDataService } from './../../services/comunication_services/albumData.service';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AlbumData } from '../../types/album-data.interface';

import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, SwiperOptions, Zoom } from 'swiper';
import { ModalController } from '@ionic/angular';
import { FullscreenComponent } from '../fullscreen/fullscreen.component';
import { Subscription } from 'rxjs';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss'],
})
export class PhotoGalleryComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChildren('element') public elements: QueryList<ElementRef>;
  public children: any = [];
  @Input() albumData: AlbumData = null;
  public myId = '';
  public isFullScreen = false;
  public forFullScreen = {
      url: '',
      index: 0,
      all: 0,
      name: '',
      type: '',
      isSave: true
  };

  public durations: any = {};

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true
  };

  private backbuttonSubscription: Subscription;

  constructor(
      private _albumDataService: AlbumDataService,
      private _galleryService: GalleryService,
      public timeConverter: TimeConverter,
      private _modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }



  async openPreview(contentData, startWith: number) {
      const modal = await this._modalCtrl.create({
        component: FullscreenComponent,
        componentProps: {
          contentData,
          startWith,
          isGallery: true
        },
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal'
      });
      return await modal.present();
  }

  ngAfterViewInit(): void {
      this.elements.changes.subscribe((c) => {
          c.toArray().forEach((item: any) => {
              this.children.push(item);
          });
      });
      this.loadElements();
  }

  ngOnDestroy(): void {
      this._albumDataService.changeDefaultData();
      this.backbuttonSubscription?.unsubscribe();
  }

  ngOnChanges() {
    this.addData();
    if (this.albumData?.contentData?.length === 0) {
      this._galleryService.getGalleryById(`${this.myId}`);
      return;
    }

    this.forFullScreen.name = this.albumData?.name;
  }

  private loadElements(): void {
      const elements = document.getElementsByClassName('element');
      for (let i = 0; i < elements.length; i++) {
          const element = elements[i] as any;
          element.onloadeddata = () => {
          };
      }
  }

  addData(){
      for(let i = 0; i < this.albumData?.contentData?.length; i++){
          this.albumData.contentData[i].isLoaded = false;
      }
  }


  public chenchLoaded(index): void{
      this.albumData.contentData[index].isLoaded = true;
  }

  public videoLoaded(event: any, index: number): void {
      const video = event.target;
      this.albumData.contentData[index].isLoaded = true;

      this.durations[index] = video.duration;
  }

  public getElem(id: number): number {
      const element = document.getElementById(`video${id}`) as HTMLVideoElement;
      return element.duration;
  }

  public videoDuration(index: number): number {
      const element = this.children[index].nativeElement;

      return element.duration;
  }

  public closeFullScreen(): void {
      this.isFullScreen = false;
  }

  public getAllAssets(): any[] {
      const assets = [];

      for (let i = 0; i < this.albumData.contentData?.length; i++) {
          const el = this.albumData.contentData[i];
          const asset = {
              url: '',
              index: 0,
              all: 0,
              name: '',
              type: '',
              isSave: true
          };
          asset.index = i;
          asset.url = el.url;
          asset.all = this.albumData.contentData?.length;
          asset.type = el.contentType;
          assets.push(asset);
      }
      this.isFullScreen = true;
      return assets;
  }

  public openFullScreen(contentData, startWith: number): void {
      this.openPreview(contentData, startWith);
  }
}
