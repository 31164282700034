
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private _host: string = environment.apiUrl;

    constructor(
        private _http: HttpClient,
    ) {
    }

    public async updateSettings(data: {
        music: boolean;
        sound: boolean;
        nickname: string;
        quickMessaging: boolean;
    }): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            const requestUrl = `${this._host}/api/settings/update`;
            const response = await this._http.put<any>(requestUrl, data).toPromise();
            resolve(true);
            return true;
        });
    }

    public updateNickname(name: string): Observable<boolean> {
      const requestUrl = `${this._host}/api/User/edit-name`;
      return this._http.put<any>(requestUrl, {name}).pipe(
        map(res =>true)
      );
    }

    public async updateAvatar(file: any): Promise<string> {
        return new Promise<string>(async (resolve) => {
            const formData = new FormData();
            formData.append('file', file);
            const requestUrl = `${this._host}/api/settings/update_avatar`;
            const response = await this._http.put<any>(requestUrl, formData).toPromise();
            response.avatar += '?t=' + new Date().getTime();
            resolve(response.avatar);
            return response.avatar;
        });
    }

    public sendReport(data): Observable<boolean> {
      const requestUrl = `${this._host}/api/Support/issue`;
      return this._http.post<any>(requestUrl, data).pipe(
        map(res =>true)
      );
    }
}
