import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    transactionId? : string;

    constructor() {
        this._init();
    }

    private _init() {
        const id = this.get('nudes_transactionId');
        if (id && id !== '') {
            this.transactionId = id;
        }
    }


    set(key: string, value: string) {
        try {
            localStorage.setItem(key, value);
        } catch (error) {
            document.cookie = `${key}=${value}`;
        }

    }

    get(key: string) {
        try {
            return localStorage.getItem(key);
        } catch (error) {
            const matches = document.cookie.match(new RegExp(
                '(?:^|; )' + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }

    }

    remove(key: string) {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            this.set(key, '');
        }
    }

    setTransactionId(transactionId: string) {
        if (transactionId) {
            this.transactionId = transactionId;
            this.set('nudes_transactionId', transactionId);
        }
    }

    removeTransactionId(): void{
        this.remove('nudes_transactionId');
        this.transactionId = '';
    }

    getTransactionId(): string | null {
        if (this.transactionId && this.transactionId !== '') {
            return this.transactionId;
        }
        return null;
    }
}
