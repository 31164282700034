import { DirectivesModule } from './../../shared/directives/directives.module';
import { BannerSliderModule } from './../../shared/components/banner-slider/banner-slider.module';
import { PhotoGalleryModule } from 'src/app/shared/components/photo-gallery/photo-gallery.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [
    ProfileComponent
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    PhotoGalleryModule,
    HttpClientModule,
    BannerSliderModule,
    DirectivesModule
  ]
})
export class ProfileModule { }
