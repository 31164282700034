import { NgModule } from '@angular/core';
import { EnterTheViewPortDirective } from './enter-the-view-port.directive';



@NgModule({
  declarations: [
    EnterTheViewPortDirective
  ],
  exports: [
    EnterTheViewPortDirective
  ]
})
export class DirectivesModule { }
