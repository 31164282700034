import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Capacitor
} from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { PushNotificationService } from './push-notification.service';

@Injectable({
  providedIn: 'root'
})
export class NativePushNotificationService {

  constructor(
    private _router: Router,
    private _pushNotificationService: PushNotificationService
  ) { }

  public init() {
    if(Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    }
  }
  private registerPush() {
    PushNotifications.requestPermissions().then(result => {
      if(result.receive === 'granted') {
        PushNotifications.register();
      } else {
        // Nothing
      }
    });

    PushNotifications.addListener('registration',
      (token: Token) => {
        this._pushNotificationService.token = {
          token: token.value,
          platform: 'android'
        };
        console.log('My token: '+ JSON.stringify(token));
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Push notification Error', + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ', JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ', JSON.stringify(notification.notification));
        if(data.detaisId) {
          // this._router.navigateByUrl(`/`);
        }
      }
    );
  }
}
