import { LottieModule } from 'ngx-lottie';
import { ChatRatingBarComponent } from './chat-rating-bar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    ChatRatingBarComponent
  ],
  imports: [
    CommonModule,
    LottieModule
  ],
  exports: [
    ChatRatingBarComponent
  ]
})
export class ChatRatingBarModule { }
