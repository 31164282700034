import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { UserMyResponse } from '../types/user-my-response.interface';
import { App } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { UpdateVersionModalComponent } from '../components/misc-alerts/update-version-modal/update-version-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CheckUpdatesService {

  updatesChecked = false;

  constructor(
    private _platform: Platform,
    private _modalCtrl: ModalController
  ) {}

  checkUpdates(userInfo: UserMyResponse) {
    if(this.updatesChecked) {
      return;
    }
    this.updatesChecked = true;
    // Only for APK
    // this._openUpdateModal();

    if(this._platform.is('hybrid')){
      App.getInfo().then(res => {
        const lastVersion = environment.buildVersion === 'erogames' ? userInfo.ve : userInfo.vd;
        if(Number(res.build) < Number(lastVersion)){
          this._openUpdateModal(res.build?.toString(), lastVersion?.toString());
        }
      });
    }
  }

  private _openUpdateModal(updateFrom: string, updateTo: string) {
    this._modalCtrl.create({
      component: UpdateVersionModalComponent,
      componentProps: {
        updateFrom,
        updateTo,
      },
      swipeToClose: true,
      showBackdrop: true,
      cssClass: 'transparent-modal',
    }).then(modal => modal.present());
  }
}
