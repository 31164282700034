<ng-container>
  <div class="modal">
    <div class="modal-head">Warning</div>
    <div class="modal-body">
      <div class="confirm-text text-center">We got a new version.<br>You have to update your apk.</div>

      <div class="buttons">
        <!-- <button
          class="btn btn-block btn-uppercase"
          type="submit"
          (click)="cancel()"
        >Not now</button> -->

        <a [href]="url" target="_blank"
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="confirm()"
        >Update</a>
      </div>
    </div>


  </div>
  <div class="modal-overlay" id="modal-overlay"></div>
</ng-container>
