<ng-container>
  <div class="modal-overlay" id="modal-overlay"></div>
    <div class="modal modal--congratulations">
        <div class="modal-head">
            Congratulations!
            <button class="close" (click)="clickClosePopUp()" *ngIf="isCloseButton">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d)">
                    <rect x="4" y="5.12134" width="3" height="25" rx="1.5" transform="rotate(-45 4 5.12134)" fill="currentColor"/>
                    <rect width="3" height="25" rx="1.5" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 24 5.12134)" fill="currentColor"/>
                    </g>
                    <defs>
                    <filter id="filter0_d" x="0.621338" y="0.621338" width="26.7573" height="26.5563" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.333333 0 0 0 0 0.333333 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    </defs>
                </svg>
            </button>
        </div>
        <div class="modal-body">
            <div class="circle">
                <div>
                    <div>
                       <img src="../../../../assets/img/gem.png" alt="">
                    </div>
                    <!-- <div class="bonus" *ngIf="dataForPopUp.chat">
                      <div class="girl-info">
                        <div class="circle-img">
                          <img [attr.src]="this.dataForPopUp.avatar" alt="" style="border-radius:50%" >
                        </div>
                        <div class="girl-name">{{dataForPopUp.chatName}}</div>
                      </div>
                    </div> -->
                </div>
            </div>
            <div class="text">
                You get:
                <!-- <div class="reward-current" *ngIf="dataForPopUp.chat">
                    Secret chat
                </div> -->
                <div class="reward-current">
                  +{{this.dataForPopUp.pendingReward.coins}}
              </div>
            </div>
            <button class="btn btn-primary" style="width: 210px;margin:0 auto" (click)="clickCollectButton()">
                Claim reward
            </button>
        </div>
    </div>
</ng-container>
