<header role="heading" class="top-bar">
  <div (click)="playPing1()" *ngIf="state[uiStatesEnum.state] !== stateEnum.chat && state[uiStatesEnum.state] !== stateEnum.album && state[uiStatesEnum.state] !== stateEnum.profile">
    <p class="location-title" *ngIf="state[uiStatesEnum.state] === stateEnum.main">Messages</p>
    <p class="location-title" *ngIf="state[uiStatesEnum.state] === stateEnum.gallery">Gallery</p>
    <p class="location-title" *ngIf="state[uiStatesEnum.state] === stateEnum.bonus || state[uiStatesEnum.state] === stateEnum.questLines || state[uiStatesEnum.state] === stateEnum.promoCode">Bonus</p>
    <p class="location-title" *ngIf="state[uiStatesEnum.state] === stateEnum.shop">Shop</p>
    <p class="location-title" *ngIf="state[uiStatesEnum.state] === stateEnum.settings">Settings</p>
    <p class="location-title" *ngIf="state[uiStatesEnum.state] === stateEnum.swipe">Swipe</p>
  </div>
  <button class="btn-prev" *ngIf="state[uiStatesEnum.state] === stateEnum.chat || state[uiStatesEnum.state] === stateEnum.album || state[uiStatesEnum.state] === stateEnum.profile" (click)="back()">
    <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d4)">
        <path d="M16 6L8 14M8 14L16 22M8 14H28" stroke="#FF826A" stroke-width="3" stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
      <defs>
        <filter id="filter0_d4" x="0.5" y="0.5" width="35" height="31" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.529412 0 0 0 0 1 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  </button>
  <div *ngIf="state[uiStatesEnum.state] === stateEnum.profile">
    <p class="location-title">Profile</p>
  </div>
  <div class="profile-box" [style.marginLeft]="state[uiStatesEnum.state] === stateEnum.album ? '-40px' : ''" *ngIf="state[uiStatesEnum.state] === stateEnum.chat || state[uiStatesEnum.state] === stateEnum.album" >
    <div class="avatar-box">
      <a (click)="gotoProfile(state[uiStatesEnum.chatId])">
        <img [attr.src]="state[uiStatesEnum.avatarUrl]" alt="girl" class="avatar" *ngIf="state[uiStatesEnum.avatarUrl] !== ''" />
      </a>
    </div>
    <div class="name-box">
      <p class="name">{{ state[uiStatesEnum.characterName] }}</p>
      <div class="status status--green" *ngIf="state[uiStatesEnum.state] === stateEnum.chat && !state[uiStatesEnum.isOffline]">
        Online
      </div>
      <div class="status status--red" *ngIf="state[uiStatesEnum.state] === stateEnum.chat && state[uiStatesEnum.isOffline]">
        Offline
      </div>
    </div>
  </div>
  <div class="coins-box" [ngClass]="{'in-chat-section': state[uiStatesEnum.state] === stateEnum.chat}">
    <img src="assets/img/gem.png" alt="gem" class="coin-dec" />
    <span class="coin">{{ state[uiStatesEnum.diamonds] }}</span>
    <button class="add-coin" (click)="this.goToShop()">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.73588 10H6.26412V6.26412H10V3.73588H6.26412V0H3.73588V3.73588H0V6.26412H3.73588V10Z" fill="white" />
      </svg>
    </button>
  </div>

  <div class="rating-bar" *ngIf="state[uiStatesEnum.state] === stateEnum.chat">
    <app-chat-rating-bar></app-chat-rating-bar>
  </div>
</header>
