import { TutorialModule } from 'src/app/shared/components/tutorial/tutorial.module';
import { LottieModule } from 'ngx-lottie';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';

@NgModule({
    declarations: [FooterComponent],
    imports: [
        CommonModule,
        LottieModule,
        TutorialModule
    ],
    exports: [FooterComponent],
})
export class FooterModule { }
