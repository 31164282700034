
export enum SoundsEnum {
  coins = 'coins',
  ping1 = 'ping1',
  ping2 = 'ping2',
  ping3 = 'ping3',
  ping4 = 'ping4',
  bonus1 = 'bonus1',
  bonus2 = 'bonus2',
  click1 = 'click1',
  click2 = 'click2',
  fingerScroll = 'fingerScroll',
  ok1 = 'ok1',
  ok2 = 'ok2',
  typing1 = 'typing1',
  accessDenied1 = 'accessDenied1',
  accessDenied2 = 'accessDenied2',
  sentMessage1 = 'sentMessage1',
  sentMessage2 = 'sentMessage2',
  tick1 = 'tick1'
}
