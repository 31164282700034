import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../../enums/auth-status.enum';
import { SignMethod } from '../../enums/sign-method.enum';
import { NavHelper } from '../../helpers';
import { Clean } from '../../helpers/clean';
import { AuthResponse } from '../../types/auth-response.interface';
import { AuthPayloadData } from '../../types/auth-payload-data.interface';
import { TwitterSignInRequest } from '../../types/twitter-signIn-request.interface';
import { AnalyticsService } from '../analytics/analytics.service';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { TutorialService } from '../comunication_services/tutorial.service';
import { AuthApiService } from './auth-api.service.ts.service';
import { AuthStateService } from './auth-state.service';
import { IncomePayloadDataService } from '../income-payload-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthMethodsService {
  constructor(
    private _authApiService: AuthApiService,
    private _authStateService: AuthStateService,
    private _tokenService: JWTTokenService,
    private _analyticsService: AnalyticsService,
    private _clean: Clean,
    private _tutorialService: TutorialService,
    private _navHelper: NavHelper,
    private _incomePayloadDataService: IncomePayloadDataService
  ) {}


  private _afterSignUp(response: AuthResponse & {method: SignMethod}): Observable<boolean> {
    if (!response.token || response.token === null) {
      return of(false);
    }
    this._tokenService.removeToken();
    this._clean.cleanSofData();
    this._tokenService.setToken(response.token);
    this._authStateService.authStatus = AuthStatus.authenticated;
    this._authStateService.isGuest =response.isGuest;
    this._analyticsService.authorize(response.method, response.isNewUser ? 'sign_up' : 'log_in');
    if (!response.tutorialIsPassed) {
      this._tutorialService.setTutorialDataAndRun({
        tutorialChatId: response.tutorialChatId,
        tutorialIsPassed: response.tutorialIsPassed,
        tutorialStep: response.tutorialStep,
      });
    } else {
      if (environment.buildVersion === 'erogames') {
        this._navHelper.goToMain();
      }

      if(response.firstChatId) {
        this._navHelper.goToChat(response.firstChatId);
      }
    }
    this._authStateService.hideRegistrationForm();
    return of(true);
  }

  signIn(
    email: string,
    password: string
  ): Observable<boolean> {
    const payload = this._getAndRemovePayloadData();
    const data = {
      email,
      password,
      payload,
    };
    return this._authApiService
      .signIn(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.email,
        }))
      );
  }

  signUp(
    email: string,
    password: string,
    nickName: string
  ): Observable<boolean> {
    const payload = this._getAndRemovePayloadData();
    const data = {
      email,
      password,
      nickName,
      payload,
    };
    return this._authApiService
      .signUp(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.email,
        }))
      );
  }

  signUpWithGoogle(
    tokenId: string
  ): Observable<boolean> {
    const payload = this._getAndRemovePayloadData();
    const data = {
      token: tokenId,
      payload,
    };
    return this._authApiService
      .signUpWithGoogle(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.google,
        }))
      );
  }

  signUpWithDiscord(
    code: string
  ): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload = this._getAndRemovePayloadData();
    const data = {
      code,
      redirectUrl: window.location.origin,
      payload,
    };
    return this._authApiService
      .signUpWithDiscord(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.discord,
        })),
        catchError((error) => {
          this.logout();
          throw error;
        })
      );
  }

  signWithTwitter(
    data: TwitterSignInRequest
  ): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    return this._authApiService
      .signWithTwitter({
        ...data,
        payload,
      })
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.twitter,
        })),
        catchError((error) => {
          this.logout();
          throw error;
        })
      );
  }

  trySignAsGuest(): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    return this._authApiService
      .trySignupAsGuest(payload)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.asGuest,
        }))
      );
  }

  signWithErogames(): Observable<boolean> {
    const erogamesToken = this._tokenService.erogamesToken;
    const whiteLabel = document.referrer && new URL(document.referrer)?.hostname || null;
    return this._authApiService
      .signWithErogames(erogamesToken, whiteLabel)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.erogames,
        }))
      );
  }

  private _getAndRemovePayloadData(): AuthPayloadData {
    return this._incomePayloadDataService.getAndRemovePayloadData();
  }

  public logout(): void {
    this._authStateService.authStatus = AuthStatus.notAuthenticated;
    this._tokenService.removeToken();
    localStorage.clear();
    window.location.href = '/';
  }

  public wipeOut() {
    this._authApiService.wipeOut().subscribe((res) => {
      localStorage.clear();
      window.location.href = '/';
    });
  }
}
