import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileData } from '../../types/profile-data.interface';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _host: string = environment.apiUrl;

  constructor(
    private _http: HttpClient
  ) {}


  getData(chatId: string): Observable<ProfileData> {
    const requestUrl = `${this._host}/api/profile`;
    return this._http.get<any>(requestUrl, {
      params: {
        chatId
      }
    });
  }
}
