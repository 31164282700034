import { BonusService } from 'src/app/shared/services/API_services/bonus.service';
import { ApiNotification } from './../../../types/api-notification.interface';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ApiNotificationsService } from 'src/app/shared/services/notifications_services/api-notifications.service';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rewards-received-modal',
  templateUrl: './rewards-received-modal.component.html',
  styleUrls: ['./rewards-received-modal.component.scss'],
})
export class RewardsReceivedModalComponent implements OnInit, OnDestroy {
  isCloseButton = false;
  isCloseLable = false;
  @Input() dataForPopUp: ApiNotification = null;
  sub: Subscription;
  constructor(
    private _apiNotificationsService: ApiNotificationsService,
    private _bonusService: BonusService,
    private _modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  public clickCollectButton(): void {
    this._modalCtrl.dismiss();
    this.sub = this._bonusService
      .getRewardsBonus(this.dataForPopUp.pendingReward.id)
      .pipe(take(1))
      .subscribe();
  }

  public clickClosePopUp(): void {
    this._modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
