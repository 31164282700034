import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UiStateService } from '../services/comunication_services/uiStates.service';

@Injectable({
    providedIn: 'root',
})
export class NavHelper {

    constructor(
        private router: Router,
        private _uiStateService: UiStateService
    ) {}

    getRouteEventUrl$(): Observable<string> {
      return this.router.events.pipe(
        map((event: any) => event.url)
        );
    }

    public goToMain(): void {
      this.goToCards();
    }

    public goToAllChats(): void {
      this.router.navigateByUrl('allChats');
    }

    public goToLaunch(): void {
        this.router.navigateByUrl('launch');
    }

    public goToChat(id: string): void {
        this.router.navigateByUrl(`allChats/${id}`);
    }

    public goToGallery(): void {
        this.router.navigateByUrl('gallery');
    }

    public goToProfile(chatId: string): void {
      this.router.navigateByUrl(`profile/${chatId}`);
    }

    public goToPersonGallery(id: string): void {
        this.router.navigateByUrl(`gallery/${id}`);
    }

    public goToComics(): void {
        this.router.navigateByUrl('comics');
    }

    public goToSettings(): void {
        this.router.navigateByUrl('settings');
    }

    public goToBonusDailyReweards(): void {
        this.router.navigateByUrl('bonus/dailyRewards');
    }

    public goToQuestLines(): void {
      this.router.navigateByUrl('bonus/questLine');
  }

    public goToShopGems(): void {
        this.router.navigateByUrl('shop/gems');
    }

    public goToCards(): void {
        this.router.navigateByUrl('swiping-chat-cards');
    }

    public goToShopChats(): void {
        this.router.navigateByUrl('shop/secret');
    }
}
