import { ChatDetailsData } from './../../types/chat-details-data.interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GemsResponseData } from './../../types/gems-response-data.interface';
import { PromoChatsPack } from '../../types/promo-chats-pack.interface';

@Injectable({
    providedIn: 'root',
})

export class ShopDataService {
    private gemsData: GemsResponseData[] = [];
    private chatsData: GemsResponseData[] = [];
    private packData: PromoChatsPack[] = [];
    public updateGemsData: BehaviorSubject<GemsResponseData[]> = new BehaviorSubject(this.gemsData);
    public updateChatsData: BehaviorSubject<GemsResponseData[]> = new BehaviorSubject(this.chatsData);
    public updatePackData: BehaviorSubject<PromoChatsPack[]> = new BehaviorSubject(this.packData);
    public openDetailsAction: Subject<ChatDetailsData> = new Subject();

    constructor() {
    }

    public changeGemsData(fieldName: string, value: any): void {
        const defaultGemsData = this.setNewData(this.gemsData, fieldName, value);
        this.gemsData = defaultGemsData;
        this.updateGemsData.next(defaultGemsData);
    }

    public changeChatsData(fieldName: string, value: any): void {
        const defaultChatsData = this.setNewData(this.chatsData, fieldName, value);
        this.chatsData = defaultChatsData;
        this.updateChatsData.next(defaultChatsData);
    }

    public setNewData(obj: any, field: string, value: any): any {
        const setPath = (object: any, path: any, value2: any) => path
            .split('.')
            .reduce((o: any, p: any, i: any) => o[p] = path.split('.').length === ++i ? value2 : o[p] || {}, object);
        setPath(obj, field, value);
        return obj;
    }

    public changeAllGems(value: GemsResponseData[]): void {
        this.gemsData = value;
        this.updateGemsData.next(this.gemsData);
    }

    public changeAllChats(value: GemsResponseData[]): void {
        this.chatsData = value;
        this.updateChatsData.next(this.chatsData);
    }

    public changePackData(value: PromoChatsPack[]): void {
        this.packData = value;
        this.updatePackData.next(this.packData);
    }

    public openDetails(details: ChatDetailsData) {
      this.openDetailsAction.next(details);
    }
}
