import { OpenNextMessage } from './../../types/open-next-message.interface';
import { UiStatesEnum } from './../../enums/ui-states.enum';
import { UnsubscribeHelper } from './../../helpers/unsubscribe.helper';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { UiStates } from '../../enums/uiStates';
import { NavHelper } from '../../helpers';
import { UiStateService } from '../../services/comunication_services/uiStates.service';
import { NewMessageService } from '../../services/comunication_services/new-message.service';
import { NextMessageEmitterService } from '../../services/comunication_services/nextMessageEmitter.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { ChatDataService } from '../../services/comunication_services/chatData.service';
import { TimeConverter } from '../../helpers/timeConverter';
import { SetChatStateForFooterData } from './set-chat-state-for-footer-data.interface';
import { ChatData } from '../../types/chat-data.interface';
import { ChatMessageType } from '../../enums/chat-message-type.enum';
import { NextMessageAnswer } from '../../types/next-message.interface';
import { ChatService } from '../../services/API_services/chat.service';
import { MessageParser } from '../../helpers/messageParser';
import { filter, take } from 'rxjs/operators';

export enum FooterChatState {
  chat = 'chat',
  lastMessage = 'lastMessage',
  offline = 'offline',
}
export interface MenuButtonsOptions {
  name: string;
  btn: AnimationItem;
  clicked: boolean;
  options: AnimationOptions;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  private _currentChatId: string = null;
  private _currentMessageId: string = null;
  public state: UiStates;
  public time = '00:00';
  public x = '';
  private subscribers: any[] = [];
  isTyping = false;
  typedOptions = {
    strings: [],
    typeSpeed: 30,
    showCursor: false,
  };
  quickMessaging = false;
  menuButtons = ['gallery', 'bonus', 'chats', 'swipe', 'shop', 'settings'];
  menuButtonsOptions: MenuButtonsOptions[] = [];

  readonly frames = {
    idle: [0, 10],
    rollover: [11, 20],
    rollout: [21, 26],
    press: [27, 52],
    toIdle: [53, 75],
  };

  answersEmpty: NextMessageAnswer[] = [{
    id: null,
    messageText: ''
  }];

  answers: NextMessageAnswer[] = this.answersEmpty;

  isFullscreen = false;
  chatState: FooterChatState = null;
  footerChatState = FooterChatState;
  timeSTR = '00:00:00';
  private timer: any;

  constructor(
    private _navHelper: NavHelper,
    private _uiStateService: UiStateService,
    public newMessageService: NewMessageService,
    private _nextMessageEmitterService: NextMessageEmitterService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _ngZone: NgZone,
    private _chatDataService: ChatDataService,
    private _timeConverter: TimeConverter,
    private _chatService: ChatService,
    public messageParser: MessageParser,
    private _newMessageService: NewMessageService
  ) {
    this.menuButtons.forEach((btn) => {
      this.menuButtonsOptions.push({
        name: btn,
        clicked: false,
        btn: null,
        options: {
          path: `/assets/animations/menu/${btn}.json`,
          autoplay: false,
          loop: false,
        },
      });
    });

  }

  setChatState(data: SetChatStateForFooterData) {
    if (data.isAdditionalFinished) {
      this.chatState = FooterChatState.lastMessage;
    } else if (data.isOffline) {
      this.chatState = FooterChatState.offline;
      this.setOfflineTimer(data);
    } else {
      this.chatState = FooterChatState.chat;
    }
  }

  setOfflineTimer(data: SetChatStateForFooterData) {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timeSTR = this._timeConverter.toHourMinSec(data.offlineTime);
    this.timer = setInterval(() => {
      if (data.offlineTime > 0) {
        data.offlineTime -= 1;
        this.timeSTR = this._timeConverter.toHourMinSec(data.offlineTime);
        if (data.offlineTime === 0) {
          // this.outButton.emit();
        }
      } else {
        clearInterval(this.timer);
        this.timeSTR = '00:00:00';
        this._newMessageService.toggleState(true);
        this.getNextMassage();
      }
    }, 1000);
  }

  ngOnInit() {

    this.subscribers.push(
      this._chatDataService.updateChatData.subscribe((res: ChatData) => {

        if(!res || !res.messages || !res.messages.length ) {
          return;
        }

        const lastMessage = res.messages && res.messages[res.messages.length - 1];

        if(lastMessage?.type === ChatMessageType.hero && lastMessage.messageText) {
          // If Hero has text filled means that he already answered. We don;t need to show the answer.
          return;
        }

        this._currentChatId = res.id;
        this._currentMessageId = lastMessage.id;

        if(lastMessage?.type === ChatMessageType.hero){
          this.answers = lastMessage.answers;
        } else {
          this.answers = this.answersEmpty;
        }

        // this.setChatState({
        //   isOffline: res.isOffline,
        //   isAdditionalFinished: res.isAdditionalFinished,
        //   offlineTime: res.offlineTime,
        // });
      })
    );
    this.subscribers.push(
      this._chatDataService.updateBlockData.subscribe(
        (blockData: OpenNextMessage) => {
          if(blockData) {
            this.setChatState({
              isOffline: blockData.isOffline,
              isAdditionalFinished: blockData.isAdditionalFinished,
              offlineTime: blockData.offlineTime,
            });
          }
        }
      )
    );
    this.subscribers.push(
      this._chatDataService.isFullscreen.subscribe((res) => {
        this.isFullscreen = res;
      })
    );

    this.subscribers.push(
      // this._nextBonusTimeService.updateNextBonusTime.subscribe(async (res) => {
      //   this.time = res.str;
      //   const bonusBtn = this.menuButtonsOptions.filter(
      //     (r) => r.name === 'bonus'
      //   )[0]?.btn;
      //   bonusBtn?.renderer?.elements[5]?.updateDocumentData({ t: this.time });
      //   bonusBtn?.playSegments([0, 1], true);
      // })
    );

    this.state = UiStates.main;

    this.subscribers.push(
      this._uiStateService.updateStateData.subscribe((res) => {
        this.state = res[UiStatesEnum.state];

        let menuItemFromState = '';
        switch (this.state) {
          case 'album':
            menuItemFromState = 'gallery';
            break;
          case 'main':
            menuItemFromState = 'chats';
            break;
          case 'questLines':
            menuItemFromState = 'bonus';
            break;
          default:
            menuItemFromState = this.state;
        }
        const menuItem = this.menuButtonsOptions.filter(
          (r) => r.name === menuItemFromState
        )[0];
        if (menuItem && menuItem.btn !== null) {
          setTimeout(() => {
            this.changeActiveMenuBtn(menuItem);
          }, 100);
        }
      })
    );
  }

  animationCreated(animationItem: AnimationItem, btnOptions: MenuButtonsOptions): void {
    btnOptions.btn = animationItem;
    btnOptions.clicked = false;
  }

  onMouseOver(event, btnOptions: MenuButtonsOptions) {
    if (btnOptions.clicked) {
      return;
    }
    this._ngZone.runOutsideAngular(() => {
      btnOptions.btn.playSegments(
        [this.frames.rollover[0], this.frames.rollover[1]],
        true
      );
    });
  }
  onMouseOut(event, btnOptions: MenuButtonsOptions) {
    if (btnOptions.clicked) {
      return;
    }
    this._ngZone.runOutsideAngular(() => {
      btnOptions.btn.playSegments(
        [this.frames.rollout[0], this.frames.rollout[1]],
        true
      );
    });
  }

  changeActiveMenuBtn(btnOptions: MenuButtonsOptions) {
    if (btnOptions.clicked) {
      return;
    }
    btnOptions.clicked = true;
    this._ngZone.runOutsideAngular(() => {
      btnOptions.btn.playSegments(
        [this.frames.press[0], this.frames.press[1]],
        true
      );
      this._backToIdle(btnOptions);
    });
  }

  private _backToIdle(btnOptions: MenuButtonsOptions) {
    this.menuButtonsOptions
      .filter((r) => r.name !== btnOptions.name)
      .forEach((res, index) => {
        if (res.clicked) {
          this._ngZone.runOutsideAngular(() => {
            res.btn.playSegments(
              [this.frames.toIdle[0], this.frames.toIdle[1]],
              true
            );
            res.clicked = false;
          });
        }
      });
  }

  public goTo(name: string) {
    if (name === 'gallery') {
      this._navHelper.goToGallery();
    }
    if (name === 'chats') {
      this._navHelper.goToAllChats();
    }
    if (name === 'settings') {
      this._navHelper.goToSettings();
    }
    if (name === 'bonus') {
      this._navHelper.goToQuestLines();
    }
    if (name === 'shop') {
      this._navHelper.goToShopGems();
    }
    if (name === 'swipe') {
      this._navHelper.goToCards();
    }
  }

  public async getNextMassage(): Promise<void> {
    this._nextMessageEmitterService.changeData('clickOnAnswer');
  }

  public clickAnswer(answer: NextMessageAnswer) {
    if(answer.id === null){
      this._nextMessageEmitterService.changeData('clickOnAnswer');
      return;
    }
    this._chatService.clickOnAnswer(answer, this._currentMessageId, this._currentChatId);
    this.answers = this.answersEmpty;
  }

  public backToChatsList() {
    this._navHelper.goToMain();
  }

  ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this.subscribers);
  }
}
