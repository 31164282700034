import { AuthStatus } from 'src/app/shared/enums/auth-status.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShowRegistrationFormData } from './show-registration-form-data';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {

  private _authStatus: BehaviorSubject<AuthStatus> = new BehaviorSubject(null);
  private _isGuest: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isShowRegistration: BehaviorSubject<ShowRegistrationFormData>  = new BehaviorSubject(null);

  set authStatus(value: AuthStatus) {
    if(value){
      this._authStatus.next(value);
    }
  }

  get authStatus$(): Observable<AuthStatus> {
    return this._authStatus.asObservable();
  }

  set isGuest(value: boolean) {
    this._isGuest.next(value);
  }

  get isGuest$(): Observable<boolean> {
    return this._isGuest.asObservable();
  }

  showRegistrationForm(data: ShowRegistrationFormData) {
    this._isShowRegistration.next(data);
  }

  hideRegistrationForm() {
    this._isShowRegistration.next(null);
  }

  get isShowRegistration$(): Observable<ShowRegistrationFormData> {
    return this._isShowRegistration.asObservable();
  }

  constructor() {}

}
