import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../../enums/auth-status.enum';
import ErogamesAuth from '../../plugins/erogames-auth';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { AuthMethodsService } from './auth-methods.service';
import { AuthStateService } from './auth-state.service';
declare const eroAuth;

const erogamesId = '4qc1legWwCNo1p78htsVSjZiqZvw8ZhUwNyWdTzjo9Y';

@Injectable({
  providedIn: 'root',
})
export class AuthErogamesService {
  private _erogamesAuth;
  private _erogamesTokenUpdateIsRunning = false;

  constructor(
    private _platform: Platform,
    private _tokenService: JWTTokenService,
    private _authMethodsService: AuthMethodsService,
    private _authStateService: AuthStateService
  ) {
    if (environment.buildVersion === 'erogames') {
      if (this._platform.is('hybrid')) {
        this._erogamesAuth = ErogamesAuth;
      } else {
        this._erogamesAuth = eroAuth;
      }
      this._authStateService.authStatus = AuthStatus.notAuthenticated;
    }
  }

  public erogamesRefreshToken(): Observable<any> {
    return from(this._erogamesAuth.refreshToken()).pipe(
      tap((token: any) => {
        if (this._platform.is('hybrid')) {
          token = JSON.parse(token);
        }
        this._tokenService.erogamesToken =
          (token && token.access_token) || token.accessToken || null;
      })
    );
  }

  private _runErogamesAuth() {
    this.erogamesRefreshToken()
      .pipe(
        switchMap(() => this._authMethodsService.signWithErogames()),
        switchMap(() => {
          const whitelabel = this._erogamesAuth.getWhitelabel();
          const whiteLabelResult = this._platform.is('hybrid')
            ? from(whitelabel)
            : of(whitelabel);
          return whiteLabelResult;
        }),
        take(1)
      )
      .subscribe((whiteLabel: any) => {
        const erogamesUrl = (whiteLabel && whiteLabel.buy_erogold_url) || whiteLabel.buyErogoldUrl;
        if (erogamesUrl) {
          const langKey = Object.keys(erogamesUrl)[0];
          localStorage.setItem('buyErogoldUrl', erogamesUrl[langKey]);
        }
        const homeUrl = whiteLabel?.url;
        if(homeUrl){
          localStorage.setItem('eroHomeUrl', homeUrl);
        }

        this._erogamesTokenUpdateIsRunning = false;
      });
  }

  public erogamesTokenUpdateOnRefresh(): void {
    if (this._erogamesTokenUpdateIsRunning === true) {
      return;
    }
    this._erogamesTokenUpdateIsRunning = true;
    if (this._platform.is('hybrid')) {
      this._initApkVersion();
    } else {
      this._initWebVersion();
    }
  }

  private _initWebVersion() {
    this._erogamesAuth.init({
      clientId: erogamesId,
    });
    this._erogamesAuth.onAuth(
      (info) => {
        this._runErogamesAuth();
      },
      (err) => {
        console.log('on Auth err', err);
      }
    );
  }

  private _initApkVersion() {
    ErogamesAuth.init(erogamesId);
    ErogamesAuth.login('en');
    ErogamesAuth.onAuth().then(user => {
      this._runErogamesAuth();
    });
  }
}
