import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlbumDataService } from '../comunication_services/albumData.service';
import { GalleryDataService } from '../comunication_services/galleryData.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GalleryService {
    private _host: string = environment.apiUrl;

    constructor(private _http: HttpClient,
        private _galleryDataService: GalleryDataService,
        private _albumDataService: AlbumDataService
    ) {}

    public getAllGallery(): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            const requestUrl = `${this._host}/api/user/gallery`;
            const response = await this._http.get<any>(requestUrl).toPromise();
            if(response.error !== 'Error') {
                this.saveGallery(response);
                resolve(true);
                return true;
            }

        });
    }

    public getGalleryById(id: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            const requestUrl = `${this._host}/api/user/gallery/${id}`;
            const response = await this._http.get<any>(requestUrl).toPromise();
            this.saveAlbum(response);
            resolve(true);
            return true;
        });
    }

    private saveGallery(gallery: any) {
        this._galleryDataService.changeAllObject(gallery);
    }

    private saveAlbum(gallery: any) {
        this._albumDataService.changeAllObject(gallery);
    }
}
