import { SplitTestCase, SplitTestCasesService } from './split-test-cases.service';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EnterNicknameModalComponent } from '../components/misc-alerts/enter-nickname-modal/enter-nickname-modal.component';
import { SettingsDataService } from './comunication_services/settingsData.sevice';


@Injectable({
  providedIn: 'root'
})
export class SetNicknameService {

  isInited = false;


  constructor(
      private _modalCtrl: ModalController,
      private _settingsDataService: SettingsDataService,
      private _splitTestCasesService: SplitTestCasesService
    ) {}

    setModal(placeWhereItShows) {
      this._modalCtrl.create({
        component: EnterNicknameModalComponent,
        componentProps: {
          placeWhereItShows
        },
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
      }).then(res => res.present());
    }

    init(isNickNameSet: boolean) {
      if(this.isInited){
        return;
      }
      this.isInited = true;
      this._settingsDataService.nicknameIsSet = isNickNameSet;

      if(this._splitTestCasesService.hasCase(SplitTestCase.stella)) {
        // We will show nickname modal in Stella Chat
      } else {
        if(!isNickNameSet) {
          this.setModal('On Init');
        }
      }
    }
}
