<ng-container *ngIf="false">
  <div class="masonry-grid">
    <ng-container>
      <div
        class="col-block"
        [class.col-block-off]="!item.isLoaded"
        *ngFor="let item of this.albumData?.contentData; let i = index"
        (click)="openFullScreen(this.albumData?.contentData, i)"
      >
        <img
          [attr.src]="item.url"
          class="element"
          (load)="chenchLoaded(i)"
          #element
          alt=""
          *ngIf="item.contentType === 'Image'"
        />
        <video
          preload="metadata"
          [attr.src]="item.url + '#t=0.001'"
          class="element"
          (loadeddata)="videoLoaded($event, i)"
          [attr.id]="'video' + i"
          #element
          *ngIf="item.contentType === 'Video'"
          (load)="videoLoaded($event, i)"
        ></video>
        <button class="video-play" *ngIf="item.contentType === 'Video'">
          <span class="btn-play"></span>
          <span *ngIf="durations[i]">{{
            this.timeConverter.toMinSec(durations[i])
          }}</span>
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>

<swiper
  [slidesPerView]="4"
  [spaceBetween]="10"
  [centeredSlides]="true"
  class="mySwiper"
>
  <ng-template
    swiperSlide
    *ngFor="let item of this.albumData?.contentData; let i = index"
  >
    <div
      class="col-block"
      [class.col-block-off]="!item.isLoaded"
      (click)="openFullScreen(this.albumData?.contentData, i)"
    >
      <div
        [style.background-image]="'url(' + item.url + ')'"
        class="element image-as-bg"
        *ngIf="item.contentType !== 'Video'"
      ></div>
      <img
        style="display: none"
        [attr.src]="item.url"
        class="element"
        (load)="chenchLoaded(i)"
        #element
        alt=""
        *ngIf="item.contentType === 'Image'"
      />
      <div class="slider-video-container">
        <video
          preload="metadata"
          [attr.src]="item.url + '#t=0.001'"
          class="element"
          (loadeddata)="videoLoaded($event, i)"
          [attr.id]="'video' + i"
          #element
          *ngIf="item.contentType === 'Video'"
          (load)="videoLoaded($event, i)"
        ></video>
      </div>
    </div>
  </ng-template>
</swiper>
