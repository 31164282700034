import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PromoBrand } from '../enums/chat-promo-brand.enum';
import { ChatData } from '../types/chat-data.interface';
import { HelpersService } from './helpers.service';

@Injectable({
  providedIn: 'root'
})
export class PromoDatifyService {

  constructor(
    private _helpersService: HelpersService,
  ) { }

  private _addPromoChatToListOfChats(listOfChats: ChatData[]) {
    listOfChats.splice(this._helpersService.randomIntFromInterval(0, 0), 0, {
      characterAvatar: null,
      characterName: null,
      createdAt: '',
      id: '',
      messages: null,
      modifiedAt: '',
      packId: null,
      status: 'Open',
      users: null,
      feedbackFormShow: false,
      promoWidget: 'https://makeyourwet.com/M8Tt1SbB?p1=main1',
      promoCompany: PromoBrand.datify,
      promoBanner: PromoBrand.datify,
    } as ChatData);
    return listOfChats;
  }

  addPromoWidgeToChat(response: ChatData[]): Observable<ChatData[]> {
    const listOfChats = response;
    return of(this._addPromoChatToListOfChats(listOfChats));
  }
}


