<ul class="tab-nav" *ngIf="this.state !== 'chat'">
  <li *ngFor="let menuButton of menuButtonsOptions">
    <button (click)="goTo(menuButton.name)">
      <div class="lottie-btn-container">
        <div (mouseover)="onMouseOver($event, menuButton)"
          (mouseout)="onMouseOut($event, menuButton)">
        </div>
        <ng-lottie
          class="animated-btn"
          [options]="menuButton.options"
          (animationCreated)="animationCreated($event, menuButton)"
        ></ng-lottie>
      </div>
    </button>
  </li>
</ul>
<div class="tab-bar tab-bar--auto footer-chat-section"  *ngIf="this.state === 'chat'">
  <div class="tab-box" >
    <form class="w-100">
      <div class="answer-message-btn-wrapper" *ngIf="chatState === footerChatState.chat">
        <div class="answer-message-btn" *ngFor="let answer of answers" (click)="clickAnswer(answer)">
          <div class="answer-message-btn-text-area">
            <ng-container
              *ngFor="
                let part of this.messageParser.parseEmoji(
                  answer.messageText,
                  false
                )
              "
            >
              <span *ngIf="this.messageParser.checkEmoji(part)">{{
                this.messageParser.getEmoji(part)
              }}</span>
              <span *ngIf="!this.messageParser.checkEmoji(part)">{{
                part
              }}</span>
            </ng-container>
          </div>
          <div class="answer-message-btn-send-area"></div>
        </div>
      </div>
      <div *ngIf="chatState === footerChatState.lastMessage" (click)="backToChatsList()" class="big-tap-btn">Back to chat list</div>
      <div *ngIf="chatState === footerChatState.offline" class="big-tap-btn offline-timer-btn" (click)="getNextMassage()">
        User is offline
        <div class="timer-box">
          <div class="timer timer-info">
              <svg width="36" height="41" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.5318 4.6416C21.9213 4.47673 21.2948 4.83894 21.1305 5.45154L20.5382 7.65829L24.9564 8.84161L25.5487 6.63485C25.7107 6.02287 25.3493 5.39843 24.7388 5.23356L22.5318 4.6416Z" fill="#D1CFDA"/>
                  <path d="M5.81309 9.12141L4.19808 10.7378C3.7518 11.1858 3.75096 11.9071 4.19708 12.3563L5.81339 13.9714L9.04804 10.7375L7.4317 9.12252C6.98302 8.67382 6.25943 8.67359 5.81309 9.12141Z" fill="#D1CFDA"/>
                  <path d="M14.6901 8.55196C15.3886 8.36482 16.0891 8.25035 16.7892 8.166L16.3103 6.37866L17.7203 5.98929L17.1008 3.67743C16.9486 3.1095 16.3659 2.77307 15.798 2.92524L10.9281 4.23011C10.3602 4.38229 10.0238 4.965 10.176 5.53293L10.7954 7.8448L12.2003 7.47993L12.6792 9.26727C13.3277 8.99026 13.9917 8.73911 14.6901 8.55196Z" fill="#D1CFDA"/>
                  <path d="M16.2871 14.5122C11.5434 15.7833 8.72813 20.6591 9.99927 25.4031C11.2704 30.1471 16.1462 32.9621 20.8902 31.691C25.6341 30.4198 28.4491 25.5438 27.178 20.8001C25.9069 16.0564 21.0308 13.2412 16.2871 14.5122ZM20.2454 24.3446C19.8697 24.9309 18.8017 24.9173 17.8599 24.3142C16.918 23.7109 16.4586 22.7465 16.8342 22.1601C17.2098 21.5738 20.999 17.9314 22.3058 17.3718C22.3438 18.7927 20.621 23.7582 20.2454 24.3446Z" fill="#D1CFDA"/>
                  <path d="M15.0094 9.74404C7.63221 11.7208 3.25427 19.3035 5.23099 26.6807C7.20771 34.0579 14.7905 38.4358 22.1676 36.4591C29.5448 34.4824 33.9227 26.8996 31.946 19.5224C29.9693 12.1452 22.3865 7.76734 15.0094 9.74404ZM21.4926 33.9396C15.5068 35.5435 9.35432 31.9913 7.75045 26.0056C6.14658 20.0199 9.69872 13.8671 15.6844 12.2632C21.6701 10.6594 27.823 14.2118 29.4268 20.1974C31.0307 26.1832 27.4783 32.3357 21.4926 33.9396Z" fill="#D1CFDA"/>
              </svg>
              {{this.timeSTR}}
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
