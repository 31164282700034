import { EnvironmentModel } from './environment-model.interface';

export const environment: EnvironmentModel = {
  appId: 'com.fgf.getmynudes',
  appName: 'GetMyNudes',
  gameName: 'Get My Nudes',
  production: true,
  mode: 'test',
  apiUrl: 'https://stagingapi.getmynudes.com',
  buildVersion: 'default',
  webSiteUrl: 'https://getmynudes.com',
  showTncs: true,
  allowRegistrationForm: true,
  allowPaymentIframe: true,
  isPromoWidgetChat: true,
  splashScreenImgSrc: 'assets/imgx/splash-bg.png',
  firebaseConfig: {
    apiKey: 'AIzaSyCchXDxWly2G8Pf2pyWwU49SxxRo3rZ12A',
    authDomain: 'getmynudes-d4d83.firebaseapp.com',
    projectId: 'getmynudes-d4d83',
    storageBucket: 'getmynudes-d4d83.appspot.com',
    messagingSenderId: '215449707370',
    appId: '1:215449707370:web:1404c575674bc96385bb13',
    measurementId: 'G-3PN0MYE8RD'
  }
};
