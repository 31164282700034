import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChatDataService } from '../services/comunication_services/chatData.service';

@Injectable({
    providedIn: 'root',
})
export class MessageParser {
    constructor(
    ) { }

    public parseSistemMessage(message: string): any {
        const str = message;

        if (!str) {
            return;
        }

        const result = str.match(/\<(.*?)\>/gmi);
        let text = '';
        let id = '';
        if (result) {
            text = result[0];
            id = result[1];
            if (id) {
                return {
                    text: text.replace('<', '').replace('>', ''),
                    id: id.replace('<', '').replace('>', ''),
                };
            } else {
                return {
                    text: text.replace('<', '').replace('>', ''),
                    id: '',
                };
            }
        } else {
            return {
                text: str,
                id: '',
            };
        }
    }

    public parseEmoji(message: string, isAllChats: boolean): any {
        const str = message;

        if(!str) {
          return '';
        }

        const test = str.split(/\<(.*?)\>/gmi);
        if (isAllChats) {
            let maxLenght = 40;
            let end = false;
            for (let i = 0; i < test.length; i++) {
                if (end === true) {
                    test[i] = '';
                } else {
                    maxLenght -= test[i].length;
                    if (maxLenght < 0) {
                        if (this.checkEmoji(test[i])) {
                            test[i] = '';
                        } else {
                            test[i] = test[i].slice(0, test[i].length + maxLenght);
                        }
                        test[i] += '...';
                        end = true;
                    }
                }
            }
        }
        return test;
    }

    public getEmoji(str: string): any {
        let emoji = '';
        const a = str.replace('U+', '0x');
        try {
            emoji = String.fromCodePoint(+a);
        } catch (e) {
            // throw e;
            emoji = str;
        }

        return emoji;
    }

    public checkEmoji(str = ''): boolean {
        return str.includes('U+');
    }

}
