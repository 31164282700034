/* eslint-disable @typescript-eslint/naming-convention */
import { SwipingCardType } from './../API_services/swiping-cards.interface';
import { PromoAnalyticsData } from 'src/app/shared/types/show-promo-analytics-options.interface';
import { TutorialService } from './../comunication_services/tutorial.service';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import amplitude from 'amplitude-js';
// import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { environment } from 'src/environments/environment';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { SettingsDataService } from '../comunication_services/settingsData.sevice';
import { filter, take } from 'rxjs/operators';
import { SignMethod } from '../../enums/sign-method.enum';
import { PurchaseData } from '../../types/purchase-data.interface';
import { App } from '@capacitor/app';
import { ShowRegistrationFormData } from '../auth/show-registration-form-data';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const GameAnalytics;
// import {GameAnalytics} from 'cordova-plugin-gameanalytics';
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private isGameStarted = false;
  // public dtd: any;
  private isInit = false;
  gameAnalytics = null;
  buildVersion = environment.buildVersion;
  constructor(
    private _platform: Platform,
    // private ga: GoogleAnalytics,
    // private ym: NgxMetrikaService,
    private _tutorialService: TutorialService,
    private _tokenService: JWTTokenService,
    private _settingsDataService: SettingsDataService
  ) {}

  private initGamesAnalytics() {

    this.gameAnalytics = GameAnalytics;
    this._settingsDataService.updateSettingsData.pipe(
      filter(res => res.id !== null),
      take(1)
    ).subscribe(res => {
      try {
        setTimeout(() => {
          const devicePlatform = this._platform.is('hybrid') ? 'apk' : 'web';
          this.gameAnalytics('configureBuild', devicePlatform + ' ' + this.buildVersion + ' 1.0.0');
          this.gameAnalytics('setEnabledInfoLog', false);
          this.gameAnalytics('initialize', '7477916142044473c29418bb8c5da4bb', '947320155b5ee5afb4a95113a5e31a824da815d1');
          // console.log('gameanalytics.q', GameAnalytics.q);
        });
      } catch(e) {
        console.error(e);
      }
    });
  }

  public init(): void {
    // console.log('android', this._platform.is('android'));
    // console.log('cordova', this._platform.is('cordova'));
    // console.log('ios', this._platform.is('ios'));
    // console.log('ipad', this._platform.is('ipad'));
    // console.log('iphone', this._platform.is('iphone'));
    // console.log('phablet', this._platform.is('phablet'));
    // console.log('tablet', this._platform.is('tablet'));
    // console.log('electron', this._platform.is('electron'));
    // console.log('pwa', this._platform.is('pwa'));
    // console.log('mobile', this._platform.is('mobile'));
    // console.log('mobileweb', this._platform.is('mobileweb'));
    // console.log('desktop', this._platform.is('desktop'));
    // console.log('hybrid', this._platform.is('hybrid'));

    if(this.isInit){
      return;
    }
    this.isInit = true;
      //@ts-ignore
      // this.dtd = devtodev;
      // this.dtd?.init('ak-0SyG3HNpRU8JxgbrtDauTC2BkYX9VW7v');

    amplitude.getInstance().init('11fb2e90e0cf1533c79d138072942a5a'); // initializes default instance of Amplitude client

    // initializes named instance of Amplitude client
    // const instance2 = amplitude.getInstance("instance").init("11fb2e90e0cf1533c79d138072942a5a");
    if(this._platform.is('hybrid')){
      App.getInfo().then(res => {
        const prefix = this.buildVersion;
        amplitude.getInstance().setVersionName(prefix + ': ' + res.build);
      });
    }
    // this.ga
    //   .startTrackerWithId('3037886580')
    //   .then(() => {
    //     this.ga.trackView('test');
    //     // Tracker is ready
    //     // You can now track pages or set additional information such as AppVersion or UserId
    //   })
    //   .catch((e) => console.log('Error starting GoogleAnalytics', e));

    this.initGamesAnalytics();

  }

  public setUserId(userId: string) {
    amplitude.getInstance().setUserId(userId);
    try {
      this.gameAnalytics('configureUserId', userId);
    } catch(e){}
  }

  public setSplitTests(splitTests: any[]) {
    const identify = new amplitude.Identify().setOnce('split_tests', splitTests);
      amplitude.getInstance().identify(identify);
  }

  public setIsGuest(isGuest: boolean) {
    const identify = new amplitude.Identify().setOnce('is_guest', isGuest);
      amplitude.getInstance().identify(identify);
  }

  public gameStart(): void {
    if (!this.isGameStarted) {
      //@ts-ignore
      // this.dtd = devtodev;
      // this.dtd?.customEvent('gameStart');
      this.isGameStarted = true;
    }
  }

  public clickOnUpdateApk(updateFrom: string, updateTo: string) {
    amplitude.getInstance().logEvent('Click on update APK', {updateFrom, updateTo});
  }

  public authorize(method: SignMethod, type: 'log_in' | 'sign_up') {

    try {
      this.gameAnalytics('addDesignEvent', 'Enter Game', 0, {method, type});
    } catch(e) {
      console.warn(e);
    }
    // this.dtd?.customEvent(type, method);
    amplitude.getInstance().logEvent(type, { method });
    // if(type==='sign_up'){
    //   this.ym.reachGoal.next({ target: 'New Registration' });
    // }
  }

  public tutorial(step: number | 'tinder', isLast: boolean): void {
    // this.dtd?.tutorialCompleted(step);

    try {
      this.gameAnalytics('addDesignEvent', 'Tutorial: Complete Step', step);
    } catch(e) {
      console.warn(e);
    }
    if(isLast){
      amplitude.getInstance().logEvent('tutorial_completed_step', { step });
    }
  }

  public showRegForm(data: ShowRegistrationFormData) {
    amplitude.getInstance().logEvent('Show Auth Form', { ...data.data });
  }

  public openMessage(messageData: { messageId: string; chatId: string }): void {
    // this.dtd?.customEvent('open_message');
    // amplitude.getInstance().logEvent('open_message', {
    //   message: messageData.messageId,
    //   chat: messageData.chatId,s
    // });
  }

  public savePhoto(chatId, photoId): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    // this.dtd?.customEvent('save_photo');
    amplitude.getInstance().logEvent('save_photo', { chatId, photoId });
  }

  public saveVideo(chatId, photoId): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    // this.dtd?.customEvent('save_video');
    amplitude.getInstance().logEvent('save_video', { chatId, photoId });
  }

  public unlockingChat(): void {
    // this.dtd?.customEvent('unlocking_chat');
    amplitude.getInstance().logEvent('unlocking_chat');
  }

  public chatIsOffline(chatId: string, messageIndex): void {
    // this.dtd?.customEvent('blocked_chat');
    amplitude.getInstance().logEvent('chat_is_offline',  {chatId, messageIndex});
  }

  public openGallery(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    // this.dtd?.customEvent('open_gallery');
    amplitude.getInstance().logEvent('open_gallery');
  }

  public openShop(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    // this.dtd?.customEvent('open_shop');
    amplitude.getInstance().logEvent('open_shop');
  }

  public openSettings(): void {
    // this.dtd?.customEvent('open_settings');
    amplitude.getInstance().logEvent('open_settings');
  }

  public skipOfflineModal(chatId: string, name: string, cost: number): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    try {
      this.gameAnalytics('addDesignEvent', 'Skip Offline Modal', 0, {chatId, name, cost});
    } catch(e) {
      console.error(e);
    }
    amplitude.getInstance().logEvent('skip_offline', {chatId, name, cost});
  }

  public openAllChats(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    // this.dtd?.customEvent('open_all_chats');
    // amplitude.getInstance().logEvent('open_all_chats'); // Disabled coz many requests
  }

  public swipeModel(id: string, name: string, isLiked: boolean, chatType: SwipingCardType): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    amplitude.getInstance().logEvent('swipe_model', {
      id,
      name,
      isLiked,
      chatType
    });
  }

  public cardsFirstMatch(id: string, name: string, isLiked: boolean, chatType: SwipingCardType) {
    amplitude.getInstance().logEvent('cards_first_match', {
      id,
      name,
      isLiked,
      chatType
    });
  }

  public swipeLimit(cardCount: number, gemsCount: number): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    amplitude.getInstance().logEvent('swipe_limit', {
      cardCount,
      gemsCount
    });
  }

  public swipeMatchSucceed(id: string, productPrice: number, currencyType: string, type: any): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    amplitude.getInstance().logEvent('swipe_match_succeed', {
      id,
      productPrice,
      currencyType,
      type
    });
  }
  public trialMatched(chatId: string): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    amplitude.getInstance().logEvent('trial_chat_matched', {
      chatId
    });
  }

  public trialPurchaseModalAppeared(chatId: string, messagesCount: number): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    amplitude.getInstance().logEvent('trial_purchase_modal_appeared', {
      chatId,
      messagesCount
    });
  }
  public trialPurchaseClickBuy(chatId: string, messagesCount: number): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    amplitude.getInstance().logEvent('trial_purchase_click_buy', {
      chatId,
      messagesCount
    });
  }

  public openSwipingCardsPage(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    amplitude.getInstance().logEvent('open_swiping_cards_page');
  }

  public openChat(id: string, name: string): void {


    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    try {
      this.gameAnalytics('addDesignEvent', 'Open Chat', 0, {name});
    } catch(e) {
      console.warn(e);
    }
    // this.dtd?.customEvent('open_chat', id, name);
    amplitude.getInstance().logEvent('open_chat', { id, name });
  }


  public buyChatForSoft(id: string, name: string): void {


    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    try {
      this.gameAnalytics('addDesignEvent', 'Buy Chat For Soft', 0, {name});
    } catch(e) {
      console.warn(e);
    }
    // this.dtd?.customEvent('open_chat', id, name);
    amplitude.getInstance().logEvent('Buy Chat For Soft', { id, name });
  }

  public clickOnPurchase(target: string, id: string, name: string): void {

    try {
      this.gameAnalytics('addDesignEvent', 'Click On Purchase', 0, {target, id, name});
    } catch(e) {
      console.warn(e);
    }

    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    // this.dtd?.customEvent('click_on_purchase', target, id, name);
    amplitude.getInstance().logEvent('click_on_purchase', { target, id, name });
  }

  public openBonus(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    try {
      this.gameAnalytics('addDesignEvent', 'Open Bonus page', 0);
    } catch(e) {
      console.warn(e);
    }
    amplitude.getInstance().logEvent('open_bonus');
  }
  public showBonus(bonus: string): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    try {
      this.gameAnalytics('addDesignEvent', 'Show Bonus', 0, {bonus});
    } catch(e) {
      console.warn(e);
    }
    amplitude.getInstance().logEvent('show_bonus', {bonus});
  }

  public getBonus(day: number, bonus?: string): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    try {
      this.gameAnalytics('addDesignEvent', 'Get Bonus', 0, {day, bonus});
    } catch(e) {
      console.warn(e);
    }

    amplitude.getInstance().logEvent('get_bonus', { bonus, day });
  }

  /* public inAppPurchase(purchaseId: string, purchaseType: string, purchaseAmount: number, purchasePrice: any): void {
        this.dtd?.inAppPurchase(purchaseId, purchaseType, purchaseAmount, purchasePrice);
        amplitude.getInstance().logEvent('inAppPurchase', {
          purchaseId, purchaseType, purchaseAmount, purchasePrice
        });
    } */
  // public clickPurchaseChatsPack(id) {
  //   if (this._tutorialService.tutorialModeOn) {
  //     return;
  //   }
  //   this.clickOnPurchase('Chats pack', id, '');
  // }

  public confirmPurchaseModal(data: PurchaseData) {
    amplitude.getInstance().logEvent('confirm_purchase_modal', {...data});
  }

  public notEnoughFunds(
    transactionId: string,
    productPrice: number,
    productName: string
  ) {

    try {
      this.gameAnalytics('addDesignEvent', 'Not Enough Funds', 0, {transactionId, productPrice, productName});
    } catch(e) {
      console.warn(e);
    }

    amplitude.getInstance().logEvent('not_enough_funds', {
      transactionId,
      productPrice,
      productName,
    });
  }

  public payment(
    paymentMethod: string,
    transactionId: string,
    productPrice: number,
    productName: string,
    lotType: string,
    source: string,
    meta: string
  ): void {
    // this.dtd?.realPayment(
    //   transactionId,
    //   productPrice,
    //   productName
    // );


    try {
      // eslint-disable-next-line max-len
      this.gameAnalytics('addDesignEvent', 'Payment Complete', productPrice, {paymentMethod, transactionId, productName, lotType, source, meta});
    } catch(e) {
      console.warn(e);
    }

    amplitude.getInstance().logEvent('realPayment', {
      paymentMethod,
      transactionId,
      productPrice,
      productName,
      lotType,
      source,
      meta
    });

    const revenue = new amplitude.Revenue().setProductId(transactionId).setPrice(3.99).setQuantity(3);
    amplitude.getInstance().logRevenueV2(revenue);
  }

  public quickMessaging(action: boolean) {
    // this.dtd?.customEvent('quick_messaging', action);
    amplitude.getInstance().logEvent('quick_messaging', {
      action,
    });
  }

  public music(action: 'on' | 'off') {
    // this.dtd?.customEvent('turn_music', action);
    amplitude.getInstance().logEvent('turn_music', { action });
  }

  public sounds(action: 'on' | 'off') {
    // this.dtd?.customEvent('turn_sounds', action);
    amplitude.getInstance().logEvent('turn_sounds', { action });
  }

  public logout() {

    try {
      this.gameAnalytics('addDesignEvent', 'Log Out', 0);
    } catch(e) {
      console.error(e);
    }

    amplitude.getInstance().logEvent('log_out');
  }

  public buyAdditionalContent(action: 'buy' | 'close', name: string) {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    try {
      this.gameAnalytics('addDesignEvent', 'Buy Additional Content', 0, {name, action});
    } catch(e) {
      console.error(e);
    }
    amplitude.getInstance().logEvent('buy_additional_content', { action, name });
  }

  public clickIdSet(clickId: string, partnerId: string) {
    try {
      const identify = new amplitude.Identify().setOnce('click_id', clickId).setOnce('partner_id', partnerId);
      amplitude.getInstance().identify(identify);
      // this.dtd?.user.set('click_id', clickId, partnerId);
    } catch (e) {
      console.error('Analytics click id set error', e);
    }
  }

  public landingBehaviorSet(landingBehavior: string) {
    try {
      const identify = new amplitude.Identify().setOnce('landing_behavior', landingBehavior);
      amplitude.getInstance().identify(identify);
    } catch (e) {
      console.error('Analytics landing behavior set error', e);
    }
  }

  public trafficSourceSet(ts: string) {
    // DEPRECATED TODO remove all trsrc
    try {
      const identify = new amplitude.Identify().setOnce('traffic_source', ts);
      amplitude.getInstance().identify(identify);
    } catch (e) {
      console.error('Analytics traffic source set error', e);
    }
  }

  public utmSourceSet(utmSource: string, utmMedium: string, utmCampaign: string, utmTerm: string) {
    try {
      const identify = new amplitude.Identify()
        .setOnce('utm_source', utmSource)
        .setOnce('utm_medium', utmMedium)
        .setOnce('utm_campaign', utmCampaign)
        .setOnce('utm_term', utmTerm);
      amplitude.getInstance().identify(identify);
    } catch (e) {
      console.error('Analytics traffic source set error', e);
    }
  }

  public setPlatform() {
    const devicePlatform = this._platform.is('hybrid') ? 'apk' : 'web';
    try {
      const identify = new amplitude.Identify()
        .setOnce('host_platform', environment.buildVersion)
        .setOnce('device_patform', devicePlatform)
        .setOnce('user_patforms', this._platform.platforms());
      amplitude.getInstance().identify(identify);
      // this.dtd?.user.set('host_platform', environment.buildVersion);
      // this.dtd?.user.set('device_patform', devicePlatform);
      // this.dtd?.user.set('user_patforms', this._platform.platforms());
    } catch (e) {
      console.error('Analytics set platforms error', e);
    }
  }

  private _preparePromoData(data: PromoAnalyticsData): string {

    const nameArr = [];
    if(data.section) {
      nameArr.push(data.section);
    }
    if(data.chatName) {
      nameArr.push(data.chatName);
    }
    if(data.placeInSection) {
      nameArr.push(data.placeInSection);
    }
    if(data.positionInChatList) {
      nameArr.push(data.positionInChatList);
    }
    if(data.messageIndex) {
      nameArr.push(data.messageIndex);
    }
    if(data.bannerSlide) {
      nameArr.push(data.bannerSlide);
    }

    const name = nameArr.join(' / ');
    return name;
  }

  private _getLinkParams(link): any {
    let linkParams;
    if(link) {
      const url = new URL(link);
      const params = new URLSearchParams(url.search);
      linkParams = Object.fromEntries(params.entries());
    } else {
      linkParams = {};
    }
    return linkParams;
  }

  public clickOnPromoWidget(data: PromoAnalyticsData) {


    const name = this._preparePromoData(data);

    const linkParams = this._getLinkParams(data.link);

    // promoCompany: string, name: string, positionInChatsList: number, bannerPlaced?: string

    this._settingsDataService.updateSettingsData.pipe(
      filter(res => res.id !== null),
      take(1)
    ).subscribe(res => {

      try {
        this.gameAnalytics('addAdEvent', 'Clicked', 'Banner', data.promo, name, ...linkParams);
      } catch(e) {
        console.error(e);
      }

      amplitude.getInstance().logEvent('click on promo', {
        userId: res.id,
        sessionid: this._tokenService.getToken(),
        promoCompany: data.promo,
        name
      });
    });
  }

  public promoIsShown(data: PromoAnalyticsData) {

    const name = this._preparePromoData(data);

    const linkParams = this._getLinkParams(data.link);

    try {
      this.gameAnalytics('addAdEvent', 'Show', 'Banner', data.promo, name);
    } catch(e) {
      console.warn(e);
    }
    amplitude.getInstance().logEvent('Promo Is Shown', {promoCompany: data.promo, name, ...linkParams });
  }

  public clickOnDiscordLink(place = 'chats') {

    try {
      this.gameAnalytics('addAdEvent', 'Clicked', 'Banner', 'Discord', '', {
        adPlacement: place
      });
    } catch(e) {
      console.error(e);
    }
    amplitude.getInstance().logEvent('click on Discord', {place});
  }

  public activatedPromoCode(promoCode: string) {
    amplitude.getInstance().logEvent('Activated Promo Code', {promoCode});
  }

  public clickOnResendEmail() {
    amplitude.getInstance().logEvent('Click on resend email');
  }

  public clickOnSubscribeToPush() {
    amplitude.getInstance().logEvent('Subscribe to Push');
  }

  public isBanned(chatId: string, name: string, messageIdx: number) {
    amplitude.getInstance().logEvent('User Is Banned', {chatId, name, messageIdx});
  }

  public clickOnUnban(chatId: string, name: string, messageId: number) {
    amplitude.getInstance().logEvent('Click on Unban btn', {chatId, name, messageId});
  }

  public unbanBoughtBySoft(chatId: string, coins: number) {
    amplitude.getInstance().logEvent('Unbun Bought By Soft', {chatId, coins});
  }

  public showGiftsModal(chatId: string, name: string) {
    amplitude.getInstance().logEvent('Show Gifts Modal', {chatId, name});
  }

  public buyGift(chatId: string, name: string, giftId: string) {
    amplitude.getInstance().logEvent('Buy Gift', {chatId, name, giftId});
  }

  public nicknameModalAppeared(place: string) {
    amplitude.getInstance().logEvent('Nickname modal appeared', {place});
  }

  public nicknameIsSet(nickname: string, place: string) {
    amplitude.getInstance().logEvent('Nickname set', {nickname, place});
  }

}
