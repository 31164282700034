<div class="form-tab">
  <button class="tab active">SIGN UP</button>
  <button class="tab" (click)="goToSignIn()">SIGN IN</button>
</div>
<div class="content el1 active">
  <form [formGroup]="form">
    <app-field *ngFor="let formItem of formArray"
      [errors]="registrationHelperService.getErrors(form, formItem.controlName)"
      [className]="formItem.className"
    >
    <input
        [type]="formItem.fieldType"
        class="form-control"
        [placeholder]="formItem.placeHolder"
        [formControlName]="formItem.controlName"
        [class.error]="
        registrationHelperService.isErrorClass(form, formItem.controlName)
        "
      />
  </app-field>
  <ng-container *ngIf="commonServerErrors">
    <div class="validation-error-block" *ngFor="let err of commonServerErrors">{{ err }}</div>
  </ng-container>
    <div class="form-offset">
      <button [class.spinner]="loading" [disabled]="loading"
        class="btn btn-primary btn-block btn-uppercase"
        type="submit"
        (click)="singUp()"
      >
        Sign up
      </button>
    </div>
  </form>
  <app-social-logins [place]="'signup'"></app-social-logins>
</div>
