import { PromoAnalyticsData } from './../../types/show-promo-analytics-options.interface';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';
import { PromoBrand } from '../../enums/chat-promo-brand.enum';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { PromoStripchatService } from '../../services/promo-stripchat.service';

const bannerList = [{
  img: '/assets/imgx/stripchat-banners/451_338x235_en_1.gif',
  url: ''
},
{
  img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_1.gif',
  url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533'
},
{
  img: '/assets/imgx/stripchat-banners/449_338x235_en_3.gif',
  url: ''
},
{
  img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_2.gif',
  url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533'
},
{
  img: '/assets/imgx/stripchat-banners/447_338x235_en_5.gif',
  url: ''
},
{
  img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_3.jpg',
  url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533'
},
{
  img: '/assets/imgx/stripchat-banners/445_338x235_en_7.gif',
  url: ''
},
{
  img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_4.jpg',
  url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533'
},
{
  img: '/assets/imgx/stripchat-banners/443_338x235_en_9.gif',
  url: ''
},
{
  img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_2.gif',
  url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533'
},
{
  img: '/assets/imgx/stripchat-banners/441_338x235_en_11.gif',
  url: ''
},
{
  img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_1.gif',
  url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533'
},
{
  img: '/assets/imgx/stripchat-banners/439_338x235_en_13.gif',
  url: ''
},
{
  img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_4.jpg',
  url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533'
}
];

@Component({
  selector: 'app-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.scss'],
})
export class BannerSliderComponent implements OnInit {
  @Input() bannerAnalyticOptions: PromoAnalyticsData = null;
  public hideBanner = !environment.isPromoWidgetChat;
  banners: {
    img: string;
    url: string;
  }[];
  getPackSub: Subscription;
  link: string;
  activeSlide = 0;
  swiperConfig: SwiperOptions = {
    autoplay: {
      delay: 8000
    },
    loop: true,
    parallax: true,
    centeredSlides: true,
    initialSlide: Math.floor(Math.random()*bannerList.length)
  };

  constructor(
    private _analyticsService: AnalyticsService,
    private _promoStripchatService: PromoStripchatService
  ) { }

  onSlideChange(event) {
    this.activeSlide = event.activeIndex;
  }

  clickOnBanner(banner: string) {
    this._analyticsService.clickOnPromoWidget({
      promo: PromoBrand.bannerSlider,
      chatName: this.bannerAnalyticOptions.chatName,
      section: this.bannerAnalyticOptions.section,
      placeInSection: this.bannerAnalyticOptions.placeInSection,
      messageIndex: this.bannerAnalyticOptions.messageIndex,
      positionInChatList: this.bannerAnalyticOptions.positionInChatList,
      bannerSlide: banner
    });
  }

  ngOnInit() {
    this.link = this._promoStripchatService.getStandaloneBannerLink();
    this.banners = bannerList.map(banner => {
      if(banner.url === '') {
        banner.url = this.link;
      }
      return banner;
    });
  }

}
